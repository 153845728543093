import { useReducer, useEffect } from "react";
import { useAuth } from "../contexts/AuthContext";
import { db } from "../firebase";
import { collection, query, where, onSnapshot } from "firebase/firestore";

const ACTIONS = {
  SET_CHILD_OPDRACHTGEVERS: "set-child-opdrachtgevers",
};

export const ROOT_OPDRACHTGEVER = { name: "Root", id: null, path: [] };

function reducer(state, { type, payload }) {
  switch (type) {
    case ACTIONS.SET_CHILD_OPDRACHTGEVERS:
      return {
        ...state,
        childOpdrachtgevers: payload.childOpdrachtgevers,
      };

    default:
      return state;
  }
}

export function useOpdrachtgever(opdrachtgeverId = null, opdrachtgever = null) {
  const { currentUser } = useAuth();
  const q = query(
    collection(db, "opdrachtgevers"),
    where("userId", "==", currentUser.uid)
  );
  const [state, dispatch] = useReducer(reducer, {
    childOpdrachtgevers: [],
  });

  useEffect(() => {
    const formatDoc = (doc) => {
      return { id: doc.id, ...doc.data() };
    };

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      dispatch({
        type: ACTIONS.SET_CHILD_OPDRACHTGEVERS,
        payload: { childOpdrachtgevers: querySnapshot.docs.map(formatDoc) },
      });
    });
  }, [opdrachtgeverId, currentUser]);

  return state;
}
