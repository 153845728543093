import React, { useState } from "react";
import {
  Form,
  Button,
  Container,
  Row,
  Modal,
  Col,
} from "@themesberg/react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faSearch } from "@fortawesome/free-solid-svg-icons";

export default function DetailViewButton({ og, data }) {
  const [open, setOpen] = useState(false);

  const [loading, setLoading] = useState(false);

  function openModal() {
    setOpen(true);
  }

  function closeModal() {
    setOpen(false);
  }

  return (
    <div>
      <Button
        variant="primary"
        className="btn-sm"
        onClick={openModal}
        disabled={loading}
      >
        <FontAwesomeIcon icon={faSearch} />
      </Button>

      <Modal show={open} onHide={closeModal} size="xl">
        <Form>
          <Modal.Header>Contractdetails</Modal.Header>
          <Modal.Body className="show-grid">
            <Container>
              <Row>
                <Col>
                  <div className="table-responsive">
                    <table className="table table-centered table-nowrap mb-0 rounded">
                      <thead className="thead-light">
                        <tr>
                          <th className="border-0 rounded-start">Eigenschap</th>
                          <th className="border-0">Waarde</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="border-0">
                            <div>
                              <span className="h6">Opdrachtnemer aanhef</span>
                            </div>
                          </td>
                          <td className="border-0 font-weight-bold">
                            {data.opdrachtnemer_aanhef}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </Col>
              </Row>
            </Container>
          </Modal.Body>
          <Modal.Footer>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
              <Button
                variant="secondary"
                onClick={closeModal}
                size="sm"
                className="me-2"
              >
                Sluiten
              </Button>
            </div>
          </Modal.Footer>
        </Form>
      </Modal>
    </div>
  );
}
