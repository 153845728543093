import React, { useState, useEffect } from "react";
import {
  Form,
  Button,
  Alert,
  Container,
  Row,
  Modal,
  Col,
} from "@themesberg/react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { db } from "../firebase";
import { setDoc, doc, serverTimestamp } from "firebase/firestore";
import { v4 as uuidV4 } from "uuid";

export default function AddMultimailButton({ og, data }) {
  const [open, setOpen] = useState(false);
  const [email, setEmail] = useState("");
  const [emailOG, setEmailOG] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState({});
  const [messageOG, setMessageOG] = useState({});
  const [submitload, setsubmitload] = useState(true);

  function openModal() {
    setOpen(true);

    setMessage({
      opdrachtgever_bedrijfsnaam: data.opdrachtgever_bedrijfsnaam,
      opdrachtnemer_aanhef: data.opdrachtnemer_aanhef,
      opdrachtnemer_achternaam: data.opdrachtnemer_achternaam,
      signurl: `https://ontzorgcontract.nl/#/contract-ondertekenen/${data.id}`,
    });

    setMessageOG({
      opdrachtgever_bedrijfsnaam: data.opdrachtgever_bedrijfsnaam,
      opdrachtnemer_aanhef: data.opdrachtgever_aanhef,
      opdrachtnemer_achternaam: data.opdrachtgever_achternaam,
      signurl: `https://ontzorgcontract.nl/#/contract-ondertekenen-og/${data.id}/${data.ogid}`,
    });
  }

  function closeModal() {
    setOpen(false);
  }

  useEffect(() => {
    if (email && emailOG) {
      setsubmitload(false);
    }
  }, [email, emailOG]);

  const submitHandler = async (e) => {
    e.preventDefault();

    const template = "ontzorgcontract";
    const subject = `Hierbij uw contract van ${data.opdrachtgever_bedrijfsnaam}.`;
    const subject2 = `U heeft een contract ter ondertekening klaar staan.`;
    try {
      setLoading(true);

      const contractRef = doc(db, "contracts", og);
      setDoc(
        contractRef,
        {
          sent: true,
          emailOn: email,
          emailOg: emailOG,
          sentAt: serverTimestamp(),
        },
        { merge: true }
      );

      const contractRef2 = doc(db, `contracts/${og}/opdrachtnemer`, "1");
      setDoc(contractRef2, { email: email, signcount: 0 }, { merge: true });

      const contractRef3 = doc(db, `contracts/${og}/opdrachtgever`, data.ogid);
      setDoc(contractRef3, { email: emailOG, signcount: 0 }, { merge: true });

      await axios.post("https://api.ontzorgcontract.nl/api/email/", {
        email,
        subject,
        message,
        template,
      });

      await axios.post("https://api.ontzorgcontract.nl/api/email/", {
        email: emailOG,
        subject: subject2,
        message: messageOG,
        template: template,
      });

      setLoading(false);
      setOpen(false);
    } catch (err) {
      setLoading(false);
      setError(err);
    }
  };

  return (
    <div>
      <Button
        variant="primary"
        className="btn-sm"
        onClick={openModal}
        disabled={loading}
      >
        <FontAwesomeIcon icon={faEnvelope} />
      </Button>

      <Modal show={open} onHide={closeModal} size="xl">
        <Form>
          <Modal.Header>Contactgegevens toevoegen</Modal.Header>
          <Modal.Body className="show-grid">
            <Container>
              <Row>
                <Col>
                  <Form.Group>
                    <Form.Label>
                      Gegevens opdrachtgever <br></br> <br></br>
                      {data.opdrachtgever_bedrijfsnaam}
                      <br></br>
                      {data.opdrachtgever_aanhef}{" "}
                      {data.opdrachtgever_achternaam}
                    </Form.Label>

                    <Form.Label></Form.Label>
                    {error && <Alert variant="danger">{error}</Alert>}
                    <Form.Control
                      className="mt-2"
                      type="email"
                      placeholder="opdrachtgever@email.nl"
                      required
                      value={emailOG}
                      onChange={(e) => setEmailOG(e.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  {" "}
                  <Form.Group>
                    <Form.Label>
                      {" "}
                      Gegevens Opdrachtnemer (ZZP'er in de meeste gevallen){" "}
                      <br></br> <br></br>
                      {data.opdrachtnemer_bedrijfsnaam}
                      <br></br>
                      {data.opdrachtnemer_aanhef}{" "}
                      {data.opdrachtnemer_achternaam}
                    </Form.Label>

                    <Form.Label></Form.Label>
                    {error && <Alert variant="danger">{error}</Alert>}
                    <Form.Control
                      className="mt-2"
                      type="email"
                      placeholder="opdrachtnemer@email.nl"
                      required
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Container>
          </Modal.Body>
          <Modal.Footer>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
              <Button
                variant="secondary"
                onClick={closeModal}
                size="sm"
                className="me-2"
              >
                Sluiten
              </Button>
              <Button
                onClick={submitHandler}
                variant="primary"
                size="sm"
                className="me-2"
                disabled={submitload}
              >
                Toevoegen & verzenden
              </Button>
            </div>
          </Modal.Footer>
        </Form>
      </Modal>
    </div>
  );
}
