import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import Preloader from "../components/Preloader";

import {
  Col,
  Row,
  Button,
  Dropdown,
  Card,
  Form,
  InputGroup,
  FormControl,
} from "@themesberg/react-bootstrap";
import { useParams, useHistory } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import { doc, setDoc, getDoc, arrayUnion } from "firebase/firestore";
import { db } from "../firebase";
import { ExportSdkClient } from "@exportsdk/client";
import {
  getStorage,
  ref,
  uploadBytes,
  getDownloadURL,
  uploadBytesResumable,
} from "firebase/storage";
import { v4 as uuidV4 } from "uuid";

export default () => {
  const [opdrachtgever, setOpdrachtgever] = useState([]);
  const [folder, setFolder] = useState([]);
  const [loading3, setLoading3] = useState(false);
  const [loading2, setLoading2] = useState("");
  const { currentUser } = useAuth();
  const { id } = useParams();
  const [contractData, setContractData] = useState([]);
  const [rendered, setRendered] = useState(false);
  const [editvis, setEditvis] = useState(false);
  const [finished, setFinished] = useState(false);

  const history = useHistory();
  const [item1, setItem1] = useState("");
  const [item2, setItem2] = useState("");
  const [item3, setItem3] = useState("");
  const [item4, setItem4] = useState("");
  const [item5, setItem5] = useState("");
  const [item6, setItem6] = useState("");

  const [klantVoorletters, setKlantVoorletters] = useState("");
  const [klantAchternaam, setKlantAchternaam] = useState("");
  const [klantGeboortedatum, setKlantGeboortedatum] = useState("");
  const [klantGeboorteplaats, setKlantGeboorteplaats] = useState("");
  const [klantWoonplaats, setKlantWoonplaats] = useState("");
  const [startDatum, setStartDatum] = useState("");
  const [startTermijn, setStarttermijn] = useState("");
  const [zorgdienst1, setZorgdienst1] = useState("");
  const [zorgdienst2, setZorgdienst2] = useState("");
  const [zorgdiensttarief1, setZorgdiensttarief1] = useState("");
  const [zorgdiensttarief2, setZorgdiensttarief2] = useState("");

  const [eindDatum, setEindDatum] = useState("");
  const [raamovkContractnr, setRaamovkContractnr] = useState("");
  const [teamNaam, setTeamNaam] = useState("");
  const [opdrachtNiveau, setOpdrachtNiveau] = useState("");

  let currentTime = moment().format("MMMM Do YYYY, h:mm:ss a");
  moment.locale("nl");
  let currentDay = moment().format("DDMMYYYY");

  const timestamp_OG2 =
    contractData.opdrachtgever_signature != null ? currentTime : "";

  function handleSubmitOvk2(e) {
    e.preventDefault();
    setLoading2(true);
    setLoading3(true);

    setDoc(
      doc(db, "contracts", id),
      {
        startTermijn: startTermijn,
        klantAchternaam: klantAchternaam,
        klantGeboortedatum: klantGeboortedatum,
        klantGeboorteplaats: klantGeboorteplaats,
        klantVoorletters: klantVoorletters,
        klantWoonplaats: klantWoonplaats,
        startDatum: startDatum,
        zorgdiensttarief1: zorgdiensttarief1,
        zorgdiensttarief2: zorgdiensttarief2,
        zorgdienst1: zorgdienst1,
        zorgdienst2: zorgdienst2,
        userId: currentUser.uid,
      },
      { merge: true }
    ).then(() => {
      const client = new ExportSdkClient(
        "ontzorgcontract_d1f07bdd-d02a-4d35-a627-f8bec7a88cc0"
      );

      const templateId = "2429908d-957f-4819-bb6a-ea8bcefbbf71";

      const templateData = {
        OG_aanhef: contractData.opdrachtgever_aanhef,
        OG_achternaam: contractData.opdrachtgever_achternaam,
        OG_adres: contractData.opdrachtgever_adres,
        OG_bedrijfsnaam: contractData.opdrachtgever_bedrijfsnaam,
        OG_kvk: contractData.opdrachtgever_kvk,
        OG_signature: contractData.opdrachtgever_signature,
        OG_voorletters: contractData.opdrachtgever_voorletters,
        OG_woonplaats: contractData.opdrachtgever_woonplaats,
        ON_aanhef: contractData.opdrachtnemer_aanhef,
        ON_achternaam: contractData.opdrachtnemer_achternaam,
        ON_adres: contractData.opdrachtnemer_adres,
        ON_bedrijfsnaam: contractData.opdrachtnemer_bedrijfsnaam,
        ON_bsn: contractData.opdrachtnemer_bsn,
        ON_geboortedatum: contractData.opdrachtnemer_geboortedatum,
        ON_iban: contractData.opdrachtnemer_iban,
        ON_kvk: contractData.opdrachtnemer_kvk,
        ON_signature: "",
        ON_voorletters: contractData.opdrachtnemer_voorletters,
        ON_woonplaats: contractData.opdrachtnemer_woonplaats,
        contract_datum: contractData.createdAt,
        timestamp_OG: currentTime,
        timestamp_OG2: timestamp_OG2,
        aanvang_diensten_termijn: startTermijn,
        klant_achternaam: klantAchternaam,
        klant_geboortedatum: klantGeboortedatum,
        klant_geboorteplaats: klantGeboorteplaats,
        klant_voorletters: klantVoorletters,
        klant_woonplaats: klantWoonplaats,
        opdracht_aanvangsdatum: startDatum,
        opdracht_tarief1: zorgdiensttarief1,
        opdracht_tarief2: zorgdiensttarief2,
        zorg_diensten: `${zorgdienst1}, ${zorgdienst2}`,
      };

      const fid = uuidV4();

      client.renderPdf(templateId, templateData).then((res) => {
        const storage = getStorage();
        const storageRef = ref(storage, `/contracts/${fid}.pdf`);
        const uploadTask = uploadBytesResumable(
          storageRef,
          new Blob([res.data], { type: "application/pdf" })
        );
        uploadTask.on(
          "state_changed",
          (snapshot) => {
            // Observe state change events such as progress, pause, and resume
            // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
            const progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log("Upload is " + progress + "% done");
            switch (snapshot.state) {
              case "paused":
                console.log("Upload is paused");
                break;
              case "running":
                console.log("Upload is running");
                break;
              default:
                break;
            }
          },
          (error) => {
            // Handle unsuccessful uploads
          },
          () => {
            // Handle successful uploads on complete
            // For instance, get the download URL: https://firebasestorage.googleapis.com/...
            uploadBytes(
              storageRef,
              new Blob([res.data], { type: "application/pdf" })
            )
              .then((snapshot) => {
                console.log("Uploaded a data_url string!");
              })
              .then(
                getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                  setDoc(
                    doc(db, "contracts", id),
                    {
                      pdf_file: downloadURL,
                    },
                    { merge: true }
                  ).then(
                    setDoc(
                      doc(db, `contracts/${id}/opdrachtnemer`, "1"),
                      {
                        pdf_file: downloadURL,
                        parentId: id,
                        overeenkomst: 2,
                        signcount: 0,
                      },
                      { merge: true }
                    )
                  );

                  history.push("/dashboard/overview");
                })
              );
          }
        );
      });
    });
  }

  function handleSubmitOvk3(e) {
    e.preventDefault();
    setLoading2(true);
    setLoading3(true);

    setDoc(
      doc(db, "contracts", id),
      {
        startDatum: startDatum,
        eindDatum: eindDatum,
        raamovkContractnr: raamovkContractnr,
        teamNaam: teamNaam,
        opdrachtNiveau: opdrachtNiveau,
        zorgdiensttarief1: zorgdiensttarief1,
        zorgdiensttarief2: zorgdiensttarief2,
        zorgdienst1: zorgdienst1,
        zorgdienst2: zorgdienst2,
        userId: currentUser.uid,
      },
      { merge: true }
    ).then(() => {
      const client = new ExportSdkClient(
        "ontzorgcontract_d1f07bdd-d02a-4d35-a627-f8bec7a88cc0"
      );

      const templateId = "b8d9b7ca-ecc0-4aa5-9989-c5efea49b844";

      const templateData = {
        OG_aanhef: contractData.opdrachtgever_aanhef,
        OG_achternaam: contractData.opdrachtgever_achternaam,
        OG_adres: contractData.opdrachtgever_adres,
        OG_bedrijfsnaam: contractData.opdrachtgever_bedrijfsnaam,
        OG_kvk: contractData.opdrachtgever_kvk,
        OG_signature: contractData.opdrachtgever_signature,
        OG_voorletters: contractData.opdrachtgever_voorletters,
        OG_woonplaats: contractData.opdrachtgever_woonplaats,
        ON_aanhef: contractData.opdrachtnemer_aanhef,
        ON_achternaam: contractData.opdrachtnemer_achternaam,
        ON_adres: contractData.opdrachtnemer_adres,
        ON_bedrijfsnaam: contractData.opdrachtnemer_bedrijfsnaam,
        ON_bsn: contractData.opdrachtnemer_bsn,
        ON_geboortedatum: contractData.opdrachtnemer_geboortedatum,
        ON_iban: contractData.opdrachtnemer_iban,
        ON_kvk: contractData.opdrachtnemer_kvk,
        ON_signature: "",
        ON_voorletters: contractData.opdrachtnemer_voorletters,
        ON_woonplaats: contractData.opdrachtnemer_woonplaats,
        contract_datum: contractData.createdAt,
        timestamp_OG: currentTime,
        timestamp_OG2: timestamp_OG2,
        aanvang_diensten_termijn: startTermijn,
        opdracht_startdatum: startDatum,
        opdracht_einddatum: eindDatum,
        team_naam: teamNaam,
        raamovk_nr: raamovkContractnr,
        opdracht_niveau: opdrachtNiveau,
        opdracht_tarief1: zorgdiensttarief1,
        opdracht_tarief2: zorgdiensttarief2,
        zorg_diensten: `${zorgdienst1}, ${zorgdienst2}`,
      };

      const fid = uuidV4();

      client.renderPdf(templateId, templateData).then((res) => {
        const storage = getStorage();
        const storageRef = ref(storage, `/contracts/${fid}.pdf`);
        const uploadTask = uploadBytesResumable(
          storageRef,
          new Blob([res.data], { type: "application/pdf" })
        );
        uploadTask.on(
          "state_changed",
          (snapshot) => {
            // Observe state change events such as progress, pause, and resume
            // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
            const progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log("Upload is " + progress + "% done");
            switch (snapshot.state) {
              case "paused":
                console.log("Upload is paused");
                break;
              case "running":
                console.log("Upload is running");
                break;
              default:
                break;
            }
          },
          (error) => {
            // Handle unsuccessful uploads
          },
          () => {
            // Handle successful uploads on complete
            // For instance, get the download URL: https://firebasestorage.googleapis.com/...
            uploadBytes(
              storageRef,
              new Blob([res.data], { type: "application/pdf" })
            )
              .then((snapshot) => {
                console.log("Uploaded a data_url string!");
              })
              .then(
                getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                  setDoc(
                    doc(db, "contracts", id),
                    {
                      pdf_file: downloadURL,
                    },
                    { merge: true }
                  ).then(
                    setDoc(
                      doc(db, `contracts/${id}/opdrachtnemer`, "1"),
                      {
                        pdf_file: downloadURL,
                        parentId: id,
                        overeenkomst: 3,
                        signcount: 0,
                      },
                      { merge: true }
                    )
                  );

                  history.push("/dashboard/overview");
                })
              );
          }
        );
      });
    });
  }

  function handleSubmit(e) {
    e.preventDefault();
    setLoading2(true);
    setLoading3(true);
    let currentTime = moment().format("MMMM Do YYYY, h:mm:ss a");

    setDoc(
      doc(db, "contracts", id),
      {
        settingsraamovereenkomst: arrayUnion(
          item1,
          item2,
          item3,
          item4,
          item5,
          item6
        ),

        userId: currentUser.uid,
      },
      { merge: true }
    ).then(() => {
      const client = new ExportSdkClient(
        "ontzorgcontract_d1f07bdd-d02a-4d35-a627-f8bec7a88cc0"
      );

      const templateId = "3546f2b4-b61b-4c4c-9226-af778556a9fc";
      const templateData = {
        OG_aanhef: contractData.opdrachtgever_aanhef,
        OG_achternaam: contractData.opdrachtgever_achternaam,
        OG_adres: contractData.opdrachtgever_adres,
        OG_bedrijfsnaam: contractData.opdrachtgever_bedrijfsnaam,
        OG_kvk: contractData.opdrachtgever_kvk,
        OG_signature: contractData.opdrachtgever_signature,
        OG_voorletters: contractData.opdrachtgever_voorletters,
        OG_woonplaats: contractData.opdrachtgever_woonplaats,
        ON_aanhef: contractData.opdrachtnemer_aanhef,
        ON_achternaam: contractData.opdrachtnemer_achternaam,
        ON_adres: contractData.opdrachtnemer_adres,
        ON_bedrijfsnaam: contractData.opdrachtnemer_bedrijfsnaam,
        ON_bsn: contractData.opdrachtnemer_bsn,
        ON_geboortedatum: contractData.opdrachtnemer_geboortedatum,
        ON_iban: contractData.opdrachtnemer_iban,
        ON_kvk: contractData.opdrachtnemer_kvk,
        ON_signature: "",
        ON_voorletters: contractData.opdrachtnemer_voorletters,
        ON_woonplaats: contractData.opdrachtnemer_woonplaats,
        betaaltermijn: item6,
        boete_dag: item4,
        boete_per: item3,
        conc2_duur: item2,
        conc_duur: item1,
        contract_datum: contractData.createdAt,
        facturatieperiode: item5,
        timestamp_OG: currentTime,
        timestamp_OG2: timestamp_OG2,
        raamovk_contractnummer: `${contractData.opdrachtnemer_achternaam}-${currentDay}`,
      };

      const fid = uuidV4();

      client.renderPdf(templateId, templateData).then((res) => {
        const storage = getStorage();
        const storageRef = ref(storage, `/contracts/${fid}.pdf`);
        const uploadTask = uploadBytesResumable(
          storageRef,
          new Blob([res.data], { type: "application/pdf" })
        );
        uploadTask.on(
          "state_changed",
          (snapshot) => {
            // Observe state change events such as progress, pause, and resume
            // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
            const progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log("Upload is " + progress + "% done");
            switch (snapshot.state) {
              case "paused":
                console.log("Upload is paused");
                break;
              case "running":
                console.log("Upload is running");
                break;
              default:
                break;
            }
          },
          (error) => {
            // Handle unsuccessful uploads
          },
          () => {
            // Handle successful uploads on complete
            // For instance, get the download URL: https://firebasestorage.googleapis.com/...
            uploadBytes(
              storageRef,
              new Blob([res.data], { type: "application/pdf" })
            )
              .then((snapshot) => {
                console.log("Uploaded a data_url string!");
              })
              .then(
                getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                  setDoc(
                    doc(db, "contracts", id),
                    {
                      pdf_file: downloadURL,
                      referentie: `${contractData.opdrachtnemer_achternaam}-${currentDay}`,
                    },
                    { merge: true }
                  ).then(
                    setDoc(
                      doc(db, `contracts/${id}/opdrachtnemer`, "1"),
                      {
                        pdf_file: downloadURL,
                        parentId: id,
                        overeenkomst: 1,
                        signcount: 0,
                      },
                      { merge: true }
                    )
                  );

                  history.push("/dashboard/overview");
                })
              );
          }
        );
      });
    });
  }

  useEffect(() => {
    const docRef = doc(db, "contracts", id);

    const fetchContracts = async () => {
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        setContractData(docSnap.data());
      } else {
        // doc.data() will be undefined in this case
        console.log("No such document!");
      }
    };
    fetchContracts();
  }, []);

  useEffect(() => {
    if (rendered) {
      const docRef = doc(db, "folders", contractData.opdrachtnemerId);
      const docRef2 = doc(db, "opdrachtgevers", contractData.opdrachtgeverId);
      const fetchFolders = async () => {
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setFolder(docSnap.data());
        } else {
          // doc.data() will be undefined in this case
          console.log("No such document!");
        }
      };

      const fetchFolders2 = async () => {
        const docSnap = await getDoc(docRef2);
        if (docSnap.exists()) {
          setOpdrachtgever(docSnap.data());
        } else {
          // doc.data() will be undefined in this case
          console.log("No such document!");
        }
      };
      fetchFolders();
      fetchFolders2();
    }
    if (!rendered) {
      setRendered(true);
    }
  }, [contractData]);

  let formElements = "";
  let contractBody = "";

  if (contractData.overeenkomst === "Raamovereenkomst") {
    formElements = (
      <Form>
        <Row>
          <Row className="align-items-center">
            <Col sm={12} className="mb-3">
              <Form.Group className="mb-2">
                {formElements}
                <Form.Label>
                  Duur concurrentiebeding vanaf einde contractrelatie:
                </Form.Label>
                <Form.Select
                  defaultValue="0"
                  onChange={(e) => setItem1(e.target.value)}
                >
                  <option key="blancoOV"></option>
                  <option value="0 maanden">0 maanden</option>
                  <option value="3 maanden">3 maanden</option>
                  <option value="6 maanden">6 maanden</option>
                  <option value="9 maanden">9 maanden</option>
                  <option value="12 maanden">12 maanden</option>
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col sm={12} className="mb-3">
              <Form.Group className="mb-2">
                <Form.Label>
                  Hieronder verstaan wij klanten waar opdrachtnemer zorg heeft
                  verleend binnen:
                </Form.Label>
                <Form.Select
                  defaultValue="0"
                  onChange={(e) => setItem2(e.target.value)}
                >
                  <option key="blancoOV"></option>
                  <option value="3">0 maanden voor einde contract</option>
                  <option value="3">3 maanden voor einde contract</option>
                  <option value="6">6 maanden voor einde contract</option>
                  <option value="9">9 maanden voor einde contract</option>
                  <option value="12">12 maanden voor einde contract</option>
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col sm={12} className="mb-3">
              <Form.Group className="mb-2">
                <Form.Label>Boete per overtreding:</Form.Label>
                <Form.Select
                  defaultValue="0"
                  onChange={(e) => setItem3(e.target.value)}
                >
                  <option key="blancoOV"></option>
                  <option value="1000 euro">1000 euro</option>
                  <option value="2000 euro">2000 euro</option>
                  <option value="3000 euro">3000 euro</option>
                  <option value="4000 euro">4000 euro</option>
                  <option value="5000 euro">5000 euro</option>
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col sm={12} className="mb-3">
              <Form.Group className="mb-2">
                <Form.Label>
                  Boete per dag dat de overtreding voort duurt:
                </Form.Label>
                <Form.Select
                  defaultValue="0"
                  onChange={(e) => setItem4(e.target.value)}
                >
                  <option key="blancoOV"></option>
                  <option key="1000" value="1000 euro">
                    1000 euro
                  </option>
                  <option key="2000" value="2000 euro">
                    2000 euro
                  </option>
                  <option key="3000" value="3000 euro">
                    3000 euro
                  </option>
                  <option key="4000" value="4000 euro">
                    4000 euro
                  </option>
                  <option key="5000" value="5000 euro">
                    5000 euro
                  </option>
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col sm={12} className="mb-3">
              <Form.Group className="mb-2">
                <Form.Label>
                  Opdrachtgever hanteert de volgende factuurperiode:
                </Form.Label>
                <Form.Select
                  defaultValue="0"
                  onChange={(e) => setItem5(e.target.value)}
                >
                  <option key="blancoOV"></option>
                  <option value="1 week">week</option>
                  <option value="4 weken">4 weken</option>
                  <option value="1 maand">maand</option>
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col sm={12} className="mb-3">
              <Form.Group className="mb-2">
                <Form.Label>Opdrachtgever voldoet betaling binnen:</Form.Label>
                <Form.Select
                  defaultValue="0"
                  onChange={(e) => {
                    setItem6(e.target.value);
                    setFinished(true);
                  }}
                >
                  <option key="blancoOV"></option>

                  <option value="2 weken na factuur">2 weken na factuur</option>
                  <option value="4 weken na factuur">4 weken na factuur</option>
                  <option value="8 weken na factuur">8 weken na factuur</option>
                  <option value="1 maand na factuur">1 maand na factuur</option>
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>
        </Row>
      </Form>
    );
    contractBody = contractBody = (
      <Card.Body className="pb-2">
        <Card.Title>
          {folder.aanhef} {folder.name} van {folder.bedrijfsnaam}{" "}
        </Card.Title>
        <Card.Subtitle className="fw-normal">&</Card.Subtitle>
        <Card.Title>
          {opdrachtgever.aanhef} {opdrachtgever.name} van{" "}
          {opdrachtgever.bedrijfsnaam}{" "}
        </Card.Title>
        <Card.Subtitle className="fw-normal">
          <br></br> komen het volgende overeen:
        </Card.Subtitle>
        {/* <Card.Text className="text-gray mb-4">asdas</Card.Text> */}

        <Card.Body className="pb-5">
          <option value="3"></option>
          <Card.Subtitle className="fw-normal pb-4">
            Opdrachtnemer onthoud zich van zorg aan klanten voor de duur van{" "}
            <strong>{item1} maanden</strong> vanaf het einde van deze
            contractrelatie.
          </Card.Subtitle>
          <Card.Subtitle className="fw-normal pb-4">
            Onder klanten verstaan wij relaties waar opdrachtnemer zorg aan
            heeft verleend binnen
            <strong> {item2} maanden</strong> voor het einde van deze
            contractrelatie.
          </Card.Subtitle>
          <Card.Subtitle className="fw-normal pb-4">
            De boete bedraagt <strong> {item3} per overtreding</strong>
            <br></br>
            En <strong> {item4} per dag dat deze voort duurt.</strong>
          </Card.Subtitle>
          <Card.Subtitle className="fw-normal pb-4">
            Opdrachtgever hanteert een factuurperiode van{" "}
            <strong> {item5}.</strong>
            <br></br>
            en voldoet de betaling binnen <strong> {item6}.</strong>
          </Card.Subtitle>
        </Card.Body>
        <div className="m-5">
          {finished && (
            <Button
              variant="primary"
              disabled={loading2}
              onClick={handleSubmit}
            >
              Voltooien
            </Button>
          )}
        </div>
      </Card.Body>
    );
  } else if (
    contractData.overeenkomst === "Overeenkomst van opdracht > zorg in natura"
  ) {
    formElements = (
      <Form>
        <strong>Informatie eindklant</strong>
        <Row>
          <Row className="align-items-center">
            <Col sm={12} className="mb-3">
              <Row className="mt-2">
                <Col xs={6}>
                  {" "}
                  <Form.Group>
                    <Form.Control
                      type="text"
                      placeholder="Voorletters"
                      required
                      value={klantVoorletters}
                      onChange={(e) => setKlantVoorletters(e.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col xs={6}>
                  {" "}
                  <Form.Group>
                    <Form.Control
                      type="text"
                      placeholder="Achternaam"
                      required
                      value={klantAchternaam}
                      onChange={(e) => setKlantAchternaam(e.target.value)}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mt-2">
                <Col xs={6}>
                  {" "}
                  <Form.Group>
                    <Form.Control
                      type="text"
                      placeholder="Geboortedatum"
                      required
                      value={klantGeboortedatum}
                      onChange={(e) => setKlantGeboortedatum(e.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col xs={6}>
                  {" "}
                  <Form.Group>
                    <Form.Control
                      type="text"
                      placeholder="Geboorteplaats"
                      required
                      value={klantGeboorteplaats}
                      onChange={(e) => setKlantGeboorteplaats(e.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col xs={12} className="pt-2">
                  {" "}
                  <Form.Group>
                    <Form.Control
                      type="text"
                      placeholder="huidge woonplaats eindklant"
                      required
                      value={klantWoonplaats}
                      onChange={(e) => setKlantWoonplaats(e.target.value)}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col sm={12} className="mb-3">
              <Form.Group className="mb-2">
                <Form.Label>Aanvangsdatum van opdracht</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Startdatum opdracht"
                  required
                  value={startDatum}
                  onChange={(e) => setStartDatum(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col sm={12} className="mb-3">
              <Form.Group className="mb-2">
                <Form.Label>Opdrachtnemer start de zorg binnen:</Form.Label>
                <Form.Select
                  defaultValue="0"
                  onChange={(e) => setStarttermijn(e.target.value)}
                >
                  <option key="blancoOV"></option>
                  <option key="1000" value="1 dag">
                    1 dags
                  </option>
                  <option key="2000" value="2 dagen">
                    2 dagen
                  </option>
                  <option key="3000" value="3 dagen">
                    3 dagen
                  </option>
                  <option key="4000" value="4 dagen">
                    4 dagen
                  </option>
                  <option key="5000" value="5 dagen">
                    5 dagen
                  </option>
                  <option key="6000" value="6 dagen">
                    6 dagen
                  </option>
                  <option key="7000" value="7 dagen">
                    7 dagen
                  </option>
                  <option key="8000" value="14 dagen">
                    14 dagen
                  </option>
                  <option key="9000" value="21 dagen">
                    21 dagen
                  </option>
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col sm={12} className="">
              <Form.Group className="">
                <Form.Label>Zorgdienst 1</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Zorgdienst 1"
                  required
                  value={zorgdienst1}
                  onChange={(e) => setZorgdienst1(e.target.value)}
                />
              </Form.Group>
            </Col>
            <Col>
              {" "}
              <InputGroup className="mb-2">
                <InputGroup.Text>€ per uur</InputGroup.Text>
                <FormControl
                  id="inlineFormInputGroup"
                  placeholder="Uurtarief"
                  value={zorgdiensttarief1}
                  onChange={(e) => {
                    setZorgdiensttarief1(e.target.value);
                    setFinished(true);
                  }}
                />
              </InputGroup>
            </Col>
            <Col sm={12} className="">
              <Form.Group className="">
                <Form.Label>Zorgdienst 2</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Zorgdienst 2"
                  required
                  value={zorgdienst2}
                  onChange={(e) => setZorgdienst2(e.target.value)}
                />
              </Form.Group>
            </Col>
            <Col>
              {" "}
              <InputGroup className="mb-2">
                <InputGroup.Text>€ per uur</InputGroup.Text>
                <FormControl
                  id="inlineFormInputGroup"
                  placeholder="Uurtarief"
                  value={zorgdiensttarief2}
                  onChange={(e) => {
                    setZorgdiensttarief2(e.target.value);
                    setFinished(true);
                  }}
                />
              </InputGroup>
            </Col>
          </Row>
        </Row>
      </Form>
    );
    contractBody = (
      <Card.Body className="pb-2">
        <Card.Title>
          {folder.aanhef} {folder.name} van {folder.bedrijfsnaam}{" "}
        </Card.Title>
        <Card.Subtitle className="fw-normal">&</Card.Subtitle>
        <Card.Title>
          {opdrachtgever.aanhef} {opdrachtgever.name} van{" "}
          {opdrachtgever.bedrijfsnaam}{" "}
        </Card.Title>
        <Card.Subtitle className="fw-normal">
          <br></br> komen het volgende overeen:
        </Card.Subtitle>
        {/* <Card.Text className="text-gray mb-4">asdas</Card.Text> */}

        <Card.Body className="pb-5 px-8">
          <option value="3"></option>
          <Card.Subtitle className="fw-normal pb-4">
            Opdrachtgever verleent aan Opdrachtnemer de opdracht, welke de
            Opdrachtnemer hierbij aanneemt, om bij klant:<br></br>
            {klantWoonplaats && (
              <div>
                {" "}
                <br></br>
                de heer/mevrouw {klantVoorletters} {klantAchternaam}
                <br></br> geboren op {klantGeboortedatum} te{" "}
                {klantGeboorteplaats}
                <br></br> momenteel woonachtig in {klantWoonplaats}
              </div>
            )}
          </Card.Subtitle>
          <Card.Subtitle className="fw-normal pb-4">
            {startDatum && (
              <div>
                De opdracht gaat van start op <strong> {startDatum}</strong>
              </div>
            )}
          </Card.Subtitle>
          <Card.Subtitle className="fw-normal pb-4">
            {startTermijn && (
              <div>
                Opdrachtnemer start binnen <strong> {startTermijn} </strong> met
                de zorgverlening.<br></br>
              </div>
            )}
          </Card.Subtitle>
          <Card.Subtitle className="fw-normal pb-4">
            {zorgdienst1 && (
              <div>
                Opdrachtnemer gaat de volgende zorgdiensten verlenen: <br></br>
                <br></br>
              </div>
            )}
            {zorgdienst1 && (
              <li>
                {zorgdienst1} tegen {zorgdiensttarief1} euro per uur
              </li>
            )}
            {zorgdienst2 && (
              <li>
                {zorgdienst2} tegen {zorgdiensttarief2} euro per uur
              </li>
            )}
          </Card.Subtitle>
        </Card.Body>
        <div className="m-2 pb-5">
          {finished && (
            <Button
              variant="primary"
              disabled={loading2}
              onClick={
                contractData.overeenkomst === "raamovereenkomst"
                  ? handleSubmit
                  : handleSubmitOvk2
              }
            >
              Voltooien
            </Button>
          )}
        </div>
      </Card.Body>
    );
  } else if (
    contractData.overeenkomst ===
    "Overeenkomst van opdracht > zorg in natura > Team"
  ) {
    formElements = (
      <Form>
        <Row>
          <Col sm={12} className="mb-3">
            <Form.Group className="mb-2">
              <Form.Label>
                Overkoepelende raaamovereenkomst contractnummer
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Contractnummer"
                required
                value={raamovkContractnr}
                onChange={(e) => setRaamovkContractnr(e.target.value)}
              />
            </Form.Group>
          </Col>
          <Col sm={12} className="mb-3">
            <Form.Group className="mb-2">
              <Form.Label>Teamnaam</Form.Label>
              <Form.Control
                type="text"
                placeholder="Naam van het team"
                required
                value={teamNaam}
                onChange={(e) => setTeamNaam(e.target.value)}
              />
            </Form.Group>
          </Col>
          <Col sm={12} className="mb-3">
            <Form.Group className="mb-2">
              <Form.Label>Opdrachtniveau</Form.Label>
              <Form.Control
                type="text"
                placeholder="Niveau van de opdracht"
                required
                value={opdrachtNiveau}
                onChange={(e) => setOpdrachtNiveau(e.target.value)}
              />
            </Form.Group>
          </Col>
          <Col sm={12} className="mb-3">
            <Form.Group className="mb-2">
              <Form.Label>Aanvangsdatum van opdracht</Form.Label>
              <Form.Control
                type="text"
                placeholder="Startdatum opdracht"
                required
                value={startDatum}
                onChange={(e) => setStartDatum(e.target.value)}
              />
            </Form.Group>
          </Col>
          <Col sm={12} className="mb-3">
            <Form.Group className="mb-2">
              <Form.Label>Einddatum van opdracht</Form.Label>
              <Form.Control
                type="text"
                placeholder="Einddatum opdracht"
                required
                value={eindDatum}
                onChange={(e) => setEindDatum(e.target.value)}
              />
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col sm={12} className="">
            <Form.Group className="">
              <Form.Label>Zorgdienst 1</Form.Label>
              <Form.Control
                type="text"
                placeholder="Zorgdienst 1"
                required
                value={zorgdienst1}
                onChange={(e) => setZorgdienst1(e.target.value)}
              />
            </Form.Group>
          </Col>
          <Col>
            {" "}
            <InputGroup className="mb-2">
              <InputGroup.Text>€ per uur</InputGroup.Text>
              <FormControl
                id="inlineFormInputGroup"
                placeholder="Uurtarief"
                value={zorgdiensttarief1}
                onChange={(e) => {
                  setZorgdiensttarief1(e.target.value);
                  setFinished(true);
                }}
              />
            </InputGroup>
          </Col>
          <Col sm={12} className="">
            <Form.Group className="">
              <Form.Label>Zorgdienst 2</Form.Label>
              <Form.Control
                type="text"
                placeholder="Zorgdienst 2"
                required
                value={zorgdienst2}
                onChange={(e) => setZorgdienst2(e.target.value)}
              />
            </Form.Group>
          </Col>
          <Col>
            {" "}
            <InputGroup className="mb-2">
              <InputGroup.Text>€ per uur</InputGroup.Text>
              <FormControl
                id="inlineFormInputGroup"
                placeholder="Uurtarief"
                value={zorgdiensttarief2}
                onChange={(e) => {
                  setZorgdiensttarief2(e.target.value);
                  setFinished(true);
                }}
              />
            </InputGroup>
          </Col>
        </Row>
      </Form>
    );
    contractBody = (
      <Card.Body className="pb-2">
        <Card.Title>
          {folder.aanhef} {folder.name} van {folder.bedrijfsnaam}{" "}
        </Card.Title>
        <Card.Subtitle className="fw-normal">&</Card.Subtitle>
        <Card.Title>
          {opdrachtgever.aanhef} {opdrachtgever.name} van{" "}
          {opdrachtgever.bedrijfsnaam}{" "}
        </Card.Title>
        <Card.Subtitle className="fw-normal">
          <br></br> komen het volgende overeen onder de raamovereenkomst met
          nummer: {raamovkContractnr}
        </Card.Subtitle>
        {/* <Card.Text className="text-gray mb-4">asdas</Card.Text> */}

        <Card.Body className="pb-5 px-8">
          <option value="3"></option>
          <Card.Subtitle className="fw-normal pb-4">
            Opdrachtgever verleent aan Opdrachtnemer de opdracht, welke de
            Opdrachtnemer hierbij aanneemt, om bij team:<br></br>
            {teamNaam && (
              <div>
                {" "}
                <br></br>
                {teamNaam}
                <br></br>
                <br></br>
                de hierna genoemde zorgdiensten te verlenen.
                <br></br>
                <br></br>
                Opdrachtnemer doet dit op het niveau van: {opdrachtNiveau}
              </div>
            )}
          </Card.Subtitle>
          <Card.Subtitle className="fw-normal pb-4">
            {startDatum && (
              <div>
                De opdracht gaat van start op <strong> {startDatum}</strong>
              </div>
            )}
          </Card.Subtitle>
          <Card.Subtitle className="fw-normal pb-4">
            {eindDatum && (
              <div>
                De opdracht eindigt op <strong> {eindDatum}</strong>
              </div>
            )}
          </Card.Subtitle>

          <Card.Subtitle className="fw-normal pb-4">
            {zorgdienst1 && (
              <div>
                Opdrachtnemer gaat de volgende zorgdiensten verlenen: <br></br>
                <br></br>
              </div>
            )}
            {zorgdienst1 && (
              <li>
                {zorgdienst1} tegen {zorgdiensttarief1} euro per uur
              </li>
            )}
            {zorgdienst2 && (
              <li>
                {zorgdienst2} tegen {zorgdiensttarief2} euro per uur
              </li>
            )}
          </Card.Subtitle>
        </Card.Body>
        <div className="m-2 pb-5">
          {finished && (
            <Button
              variant="primary"
              disabled={loading2}
              onClick={handleSubmitOvk3}
            >
              Voltooien
            </Button>
          )}
        </div>
      </Card.Body>
    );
  }

  return (
    <>
      <Preloader show={loading3} />

      <Row>
        <Col xs={12} xl={4} hidden={editvis}>
          <Card border="light" className="bg-white shadow mb-4">
            <Card.Body>
              <h5 className="mb-4">Contractspecificaties</h5>
              {formElements}
            </Card.Body>
          </Card>
        </Col>

        <Col xs={12} xl={6}>
          <Row>
            <Col xs={12}>
              <Card border="light" className="text-center p-0 mb-4 shadow">
                {contractBody}
              </Card>
            </Col>
            <Col xs={12}></Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};
