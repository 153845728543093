import React, { useState } from "react";
import { Form, Button, Modal, Alert } from "@themesberg/react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { db } from "../firebase";
import { setDoc, doc } from "firebase/firestore";

export default function AddEmailButton({ og, data }) {
  const [open, setOpen] = useState(false);
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState({});

  function openModal() {
    setOpen(true);

    setMessage({
      opdrachtgever_bedrijfsnaam: data.opdrachtgever_bedrijfsnaam,
      opdrachtnemer_aanhef: data.opdrachtnemer_aanhef,
      opdrachtnemer_achternaam: data.opdrachtnemer_achternaam,
      signurl: `https://ontzorgcontract.nl/#/contract-ondertekenen/${data.id}`,
    });
  }

  function closeModal() {
    setOpen(false);
  }

  const submitHandler = async (e) => {
    e.preventDefault();

    const template = "ontzorgcontract";
    const subject = `Hierbij uw contract van ${data.opdrachtgever_bedrijfsnaam}.`;
    try {
      setLoading(true);

      const contractRef = doc(db, "contracts", og);
      setDoc(contractRef, { sent: true, email: email }, { merge: true });

      const contractRef2 = doc(db, `contracts/${og}/opdrachtnemer`, "1");
      setDoc(contractRef2, { email: email }, { merge: true });

      const { data } = await axios.post(
        "https://api.ontzorgcontract.nl/api/email/",
        {
          email,
          subject,
          message,
          template,
        }
      );
      setLoading(false);
      setOpen(false);
    } catch (err) {
      setLoading(false);
      setError(err);
    }
  };

  return (
    <div>
      <Button
        variant="primary"
        className="btn-sm"
        onClick={openModal}
        disabled={loading}
      >
        <FontAwesomeIcon icon={faEnvelope} />
      </Button>

      <Modal show={open} onHide={closeModal}>
        <Form>
          <Modal.Body>
            <Form.Group>
              <Form.Label>Voeg een ontvanger toe</Form.Label>

              <Form.Label></Form.Label>
              {error && <Alert variant="danger">{error}</Alert>}
              <Form.Control
                className="mt-2"
                type="email"
                placeholder="voorbeeld@email.nl"
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
              <Button
                variant="secondary"
                onClick={closeModal}
                size="sm"
                className="me-2"
              >
                Sluiten
              </Button>
              <Button
                onClick={submitHandler}
                variant="primary"
                size="sm"
                className="me-2"
                disabled={loading}
              >
                Toevoegen & verzenden
              </Button>
            </div>
          </Modal.Footer>
        </Form>
      </Modal>
    </div>
  );
}
