import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCloudUploadAlt,
  faPlus,
  faBell,
} from "@fortawesome/free-solid-svg-icons";
import {
  Col,
  Row,
  Button,
  Dropdown,
  Card,
  Alert,
} from "@themesberg/react-bootstrap";
import { useOpdrachtgever } from "../../utils/useOpdrachtgever";
import { useFolder } from "../../utils/useFolder";

import ContractOverview from "../ContractOverview";

export default () => {
  const childOpdrachtgevers = useOpdrachtgever();
  const childFolders = useFolder();

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <Dropdown className="btn-toolbar">
          <Dropdown.Toggle
            as={Button}
            variant="primary"
            size="sm"
            className="me-2"
          >
            <FontAwesomeIcon icon={faPlus} className="me-2" />
            Nieuw
          </Dropdown.Toggle>
          <Dropdown.Menu className="dashboard-dropdown dropdown-menu-left mt-2">
            <Dropdown.Item className="fw-bold" href="/#contract-aanmaken">
              <FontAwesomeIcon icon={faCloudUploadAlt} className="me-2" />{" "}
              Contract opstellen
            </Dropdown.Item>
            <Dropdown.Item className="fw-bold" href="/#opdrachtnemers/nieuw">
              <FontAwesomeIcon icon={faCloudUploadAlt} className="me-2" />{" "}
              Opdrachtnemer toevoegen
            </Dropdown.Item>
            <Dropdown.Item className="fw-bold" href="/#opdrachtgever-toevoegen">
              <FontAwesomeIcon icon={faCloudUploadAlt} className="me-2" />{" "}
              Opdrachtgever toevoegen
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>

      <Row>
        {childOpdrachtgevers["childOpdrachtgevers"].length === 0 ? (
          <Col xs={12} md={6} lg={6} xl={3} className="mb-4 text-center">
            <Alert variant="info">
              <Alert.Heading className="d-flex align-items-center mt-2">
                <FontAwesomeIcon icon={faBell} className="icon icon-md me-2" />
                Nog geen opdrachtgevers ingesteld
              </Alert.Heading>
              <p>Voeg allereerst 1 of meerdere opdrachtgevers toe</p>
              <hr />
              <Button
                variant="primary"
                size="sm"
                className="me-2"
                href="/#opdrachtgever-toevoegen"
              >
                Opdrachtgever toevoegen
              </Button>
            </Alert>
          </Col>
        ) : (
          <></>
        )}
        {childFolders["childFolders"].length === 0 ? (
          <Col xs={12} md={6} lg={6} xl={3} className="mb-4 text-center">
            <Alert variant="info">
              <Alert.Heading className="d-flex align-items-center mt-2">
                <FontAwesomeIcon icon={faBell} className="icon icon-md me-2" />
                Nog geen opdrachtnemers toegevoegd
              </Alert.Heading>
              <p>Voeg allereerst 1 of meerdere opdrachtnemers toe</p>
              <hr />
              <Button
                variant="primary"
                size="sm"
                className="me-2"
                href="#/opdrachtnemers/nieuw"
              >
                Opdrachtnemer toevoegen
              </Button>
            </Alert>
          </Col>
        ) : (
          <></>
        )}

        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
          <div className="d-block mb-4 mb-md-0">
            <h4>Contractoverzicht</h4>
            <p className="mb-0">
              Hier vindt u alle eerder opgestelde contracten en de huidige
              status
            </p>
          </div>
          <div className="btn-toolbar mb-2 mb-md-0"></div>
        </div>
        <Col xs={12} xl={12} className="mb-4">
          <Card border="light" className=" p-0 mb-4 shadow">
            {" "}
            <ContractOverview />
          </Card>
        </Col>
      </Row>

      {/* <Row>
        <Col xs={12} xl={12} className="mb-4">
          <Row>
            <Col xs={12} xl={8} className="mb-4">
              <Row>
                <Col xs={12} className="mb-4">
                  <PageVisitsTable />
                </Col>

                <Col xs={12} lg={6} className="mb-4">
                  <TeamMembersWidget />
                </Col>

                <Col xs={12} lg={6} className="mb-4">
                  <ProgressTrackWidget />
                </Col>
              </Row>
            </Col>

            <Col xs={12} xl={4}>
              <Row>
                <Col xs={12} className="mb-4">
                  <BarChartWidget
                    title="Total orders"
                    value={452}
                    percentage={18.2}
                    data={totalOrders}
                  />
                </Col>

                <Col xs={12} className="px-0 mb-4">
                  <RankingWidget />
                </Col>

                <Col xs={12} className="px-0">
                  <AcquisitionWidget />
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row> */}
    </>
  );
};
