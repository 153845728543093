import { useReducer, useEffect } from "react";
import { useAuth } from "../contexts/AuthContext";
import { db } from "../firebase";
import {
  collection,
  query,
  where,
  onSnapshot,
  orderBy,
} from "firebase/firestore";

const ACTIONS = {
  SET_CHILD_CONTRACTS: "set-child-contracts",
};

export const ROOT_CONTRACT = { name: "Root", id: null, path: [] };

function reducer(state, { type, payload }) {
  switch (type) {
    case ACTIONS.SET_CHILD_CONTRACTS:
      return {
        ...state,
        childContracts: payload.childContracts,
      };

    default:
      return state;
  }
}

export function useContract(contractId = null, contract = null) {
  const { currentUser } = useAuth();
  const q = query(
    collection(db, "contracts"),
    where("userId", "==", currentUser.uid),
    where("pdf_file", "!=", null),
    orderBy("pdf_file", "asc"),
    orderBy("createdAt", "desc")
  );
  const [state, dispatch] = useReducer(reducer, {
    childContracts: [],
  });

  useEffect(() => {
    const formatDoc = (doc) => {
      return { id: doc.id, ...doc.data() };
    };

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      dispatch({
        type: ACTIONS.SET_CHILD_CONTRACTS,
        payload: { childContracts: querySnapshot.docs.map(formatDoc) },
      });
    });
  }, [contractId, currentUser]);

  return state;
}
