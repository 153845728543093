import React, { useState } from "react";
import {
  Row,
  Col,
  Card,
  Form,
  Button,
  Modal,
} from "@themesberg/react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import Pagination from "react-bootstrap-table2-paginator";
import * as Paginator from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import Moment from "react-moment";
import "react-toastify/dist/ReactToastify.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faFilePdf,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import ReactTooltip from "react-tooltip";
import AddEmailButton from "../components/AddEmailButton";
import AddMultimailButton from "../components/AddMultimailButton";
import DetailViewButton from "../components/DetailViewButton";
import { useContract } from "../utils/useContract";
import { doc, getDoc, deleteDoc } from "firebase/firestore";
import { db } from "../firebase";

export default function ContractOverview() {
  const [show, setShow] = useState(false);
  const [rowId, setRowId] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = (rowid) => {
    setShow(true);
    setRowId(rowid);
  };

  const deleteOG = async (id) => {
    await deleteDoc(doc(db, "contracts", id));
    setShow(false);
  };

  const dateFormatter = (cell) => {
    const fDate = cell.toDate();
    return <Moment format="DD MMM YYYY  HH:mm">{fDate}</Moment>;
  };

  const buttonFormatter = (cell, row) => {
    return (
      <Row>
        <Col xs={1} className="m-2">
          {row.opdrachtgever_signature ? (
            <AddEmailButton data={row} og={row.id} />
          ) : (
            <AddMultimailButton data={row} og={row.id} />
          )}
        </Col>
        <Col xs={1} className="m-2">
          <Button
            variant="primary"
            className="btn-sm px-2"
            href={row.signed_contract ? row.signed_contract : row.pdf_file}
            target="blank"
          >
            <FontAwesomeIcon
              icon={faFilePdf}
              style={{ width: "14px" }}
            ></FontAwesomeIcon>
          </Button>
        </Col>
        <Col xs={1} className="m-2">
          <DetailViewButton data={row} og={row.id} />
        </Col>

        <Col xs={1} className="m-2">
          <Button
            variant="primary"
            className="btn-sm"
            // onClick={() => deleteON(row.id)}
            onClick={(e) => handleShow(row.id)}
            target="blank"
          >
            <FontAwesomeIcon
              icon={faTrash}
              style={{ width: "14px" }}
            ></FontAwesomeIcon>
          </Button>
        </Col>
      </Row>
    );
  };

  const overeenkomstFormatter = (cell, row) => {
    return (
      <div>
        {row.overeenkomst} <br></br>
        {row.startDatum && `Startdatum: ${row.startDatum}`}
      </div>
    );
  };

  const status2Formatter = (cell, row) => {
    return (
      <div>
        {cell ? (
          <div style={{ color: "green" }}>
            <strong>Compleet</strong>
          </div>
        ) : (
          <div style={{ color: "red" }}>
            {row.sent ? (
              <strong style={{ color: "green" }}>
                Verzonden op{" "}
                {row.sentAt && (
                  <Moment format="DD MMM YYYY  HH:mm">
                    {row.sentAt.toDate()}
                  </Moment>
                )}
                <br></br>
              </strong>
            ) : (
              <strong style={{ color: "red" }}>
                Niet verzonden<br></br>
              </strong>
            )}
            {row.og_sign ? (
              <strong style={{ color: "green" }}>
                Opdrachtgever heeft getekend
                <br></br>
              </strong>
            ) : (
              <strong>
                Opdrachtgever heeft niet getekend <br></br>
              </strong>
            )}
            {row.on_sign ? (
              <strong style={{ color: "green" }}>
                Opdrachtnemer heeft getekend
              </strong>
            ) : (
              <>
                <strong>Opdrachtnemer heeft niet getekend</strong>
              </>
            )}
          </div>
        )}
      </div>
    );
  };

  const statusFormatter = (cell, row) => {
    const sentInd = row.sent;

    return (
      <>
        {sentInd ? (
          <div className="text-center">
            <span data-tip data-for="happyface">
              <FontAwesomeIcon icon={faEnvelope} />
            </span>
            <ReactTooltip
              id="happyface"
              className="custom-color-no-arrow"
              backgroundColor="#05A677"
              effect="solid"
            >
              <span>Contract verzonden</span>
            </ReactTooltip>
          </div>
        ) : (
          <div className="text-center">
            <ReactTooltip
              id="sadFace"
              className="custom-color-no-arrow"
              backgroundColor="#737683"
              effect="solid"
            >
              <span>Contract niet verzonden</span>
            </ReactTooltip>
          </div>
        )}
      </>
    );
  };

  const columns = [
    {
      dataField: "opdrachtgever_bedrijfsnaam",
      text: "Opdrachtgever",
      sort: true,
    },
    {
      dataField: "opdrachtnemer_bedrijfsnaam",
      text: "Opdrachtnemer",
      sort: true,
    },
    {
      dataField: "opdrachtnemer_achternaam",
      text: "Naam opdrachtnemer",
      sort: true,
    },
    {
      dataField: "overeenkomst",
      text: "overeenkomst",
      formatter: overeenkomstFormatter,
    },

    {
      dataField: "createdAt",
      text: "Gemaakt op",
      sort: true,
      formatter: dateFormatter,
    },
    {
      dataField: "opdrachtgeverId",
      text: "opdrachtgeverId",
      hidden: true,
    },
    {
      dataField: "opdrachtnemerId",
      text: "opdrachtnemerId",
      hidden: true,
    },
    {
      dataField: "pdf_file",
      text: "",
      hidden: true,
    },
    {
      dataField: "startDatum",
      text: "",
      hidden: true,
    },
    {
      dataField: "status",
      text: "status",
      formatter: status2Formatter,
      sort: true,
    },
    {
      dataField: "id",
      text: "",
      formatter: statusFormatter,
      sort: true,
    },

    {
      dataField: "sent",
      text: "sent",
      hidden: true,
    },
    {
      dataField: "",
      text: "acties",
      formatter: buttonFormatter,
    },
    {
      dataField: "opdrachtgever_signature",
      text: "Signature",
      hidden: true,
    },
    {
      dataField: "ogid",
      text: "ogid",
      hidden: true,
    },
    {
      dataField: "sentAt",
      text: "sentAt",
      hidden: true,
    },
    {
      dataField: "og_sign",
      text: "og_sign",
      hidden: true,
    },
    {
      dataField: "on_sign",
      text: "on_sign",
      hidden: true,
    },
    {
      dataField: "signed_contract",
      text: "signed_contract",
      hidden: true,
    },
  ];

  const customTotal = (from, to, size) => (
    <div>
      Wij tonen u contract {from} t/m {to} van {size} contracten
    </div>
  );

  const { childContracts } = useContract();

  const customSizePerPage = (props) => {
    const { options, currentSizePerPage, onSizePerPageChange } = props;

    const onPageChange = (e) => {
      const page = e.target.value;
      onSizePerPageChange(page);
    };

    return (
      <Row>
        <Col xs="auto">
          <Form.Select
            value={currentSizePerPage}
            onChange={onPageChange}
            className="pe-5"
          >
            {options.map((o) => (
              <option key={o.page} value={o.page}>
                {o.text}
              </option>
            ))}
          </Form.Select>
        </Col>
        <Col xs="auto" className="d-flex align-items-center">
          contracten per pagina
        </Col>
      </Row>
    );
  };

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Body>
            weet u zeker dat u dit contract wilt verwijderen?
          </Modal.Body>
        </Modal.Header>

        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Nee, behouden
          </Button>
          <Button
            variant="primary"
            onClick={(e) => {
              deleteOG(rowId);
            }}
          >
            Ja, verwijderen
          </Button>
        </Modal.Footer>
      </Modal>

      <ToolkitProvider
        keyField="id"
        search={true}
        columns={columns}
        data={childContracts}
        wrapperClasses="table-responsive"
      >
        {({ baseProps, searchProps }) => (
          <Paginator.PaginationProvider
            pagination={Pagination({
              custom: true,
              showTotal: true,
              alwaysShowAllBtns: true,
              totalSize: childContracts.length,
              paginationTotalRenderer: customTotal,
              sizePerPageRenderer: customSizePerPage,
            })}
          >
            {({ paginationProps, paginationTableProps }) => (
              <Card>
                <div className="table-responsive py-2">
                  <Card.Header>
                    <Row>
                      <Col xs={12} md={6} className="py-1">
                        <Paginator.SizePerPageDropdownStandalone
                          {...paginationProps}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={6}
                        className="d-flex justify-content-md-end py-1"
                      >
                        <Search.SearchBar {...searchProps} />
                      </Col>
                    </Row>
                  </Card.Header>

                  <BootstrapTable
                    {...baseProps}
                    {...paginationTableProps}
                    bodyClasses="border-0"
                    rowClasses="border-bottom"
                    classes="table-flush dataTable-table"
                  />

                  <Card.Footer>
                    <Row>
                      <Col
                        xs={12}
                        md={6}
                        className="d-flex align-items-center py-1"
                      >
                        <Paginator.PaginationTotalStandalone
                          {...paginationProps}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={6}
                        className="d-flex justify-content-md-end align-items-center mb-0 py-1"
                      >
                        <Paginator.PaginationListStandalone
                          {...paginationProps}
                        />
                      </Col>
                    </Row>
                  </Card.Footer>
                </div>
              </Card>
            )}
          </Paginator.PaginationProvider>
        )}
      </ToolkitProvider>
    </>
  );
}
