import React, { useState, useEffect } from "react";
import { Form, Button, Dropdown, Modal } from "@themesberg/react-bootstrap";
import { useAuth } from "../contexts/AuthContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTasks } from "@fortawesome/free-solid-svg-icons";
import { useParams } from "react-router-dom";

import { db } from "../firebase";
import { collection, addDoc, serverTimestamp } from "firebase/firestore";

export default function AddFolderModal({ currentFolder }) {
  const [open, setOpen] = useState(true);
  const [name, setName] = useState("");
  const [aanhef, setAanhef] = useState("");
  const [voorletters, setVoorletters] = useState("");
  const [bedrijfsnaam, setBedrijfsnaam] = useState("");
  const [adres, setAdres] = useState("");
  const [woonplaats, setWoonplaats] = useState("");
  const [geboortedatum, setGeboortedatum] = useState("");
  const [bsn, setBsn] = useState("");
  const [iban, setIban] = useState("");
  const [kvk, setKvk] = useState("");
  const { currentUser } = useAuth();
  const [loading, setLoading] = useState(false);
  const [validated, setValidated] = useState(false);

  function openModal() {
    setOpen(true);
  }

  function closeModal() {
    setOpen(false);
  }

  async function handleSubmit(e) {
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    } else {
      e.preventDefault();
      setLoading(true);

      await addDoc(collection(db, "folders"), {
        aanhef: aanhef,
        voorletters: voorletters,
        name: name,
        bedrijfsnaam: bedrijfsnaam,
        adres: adres,
        woonplaats: woonplaats,
        geboortedatum: geboortedatum,
        bsn: bsn,
        iban: iban,
        kvk: kvk,
        userId: currentUser.uid,
        createdAt: serverTimestamp(),
      });

      setAanhef("");
      setVoorletters("");
      setBedrijfsnaam("");
      setAdres("");
      setWoonplaats("");
      setGeboortedatum("");
      setBsn("");
      setIban("");
      setKvk("");
      setName("");
      setLoading(false);
      setOpen(false);
    }
    setValidated(true);
  }

  return (
    <>
      <Modal show={open} onHide={closeModal}>
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <Modal.Body>
            <Form.Group>
              <Form.Label>Nieuwe opdrachtnemer</Form.Label>

              <Form.Label></Form.Label>
              <Form.Control
                className="mt-2"
                as="select"
                value={aanhef}
                onChange={(e) => {
                  setAanhef(e.target.value);
                }}
              >
                <option value=""></option>
                <option value="Mevrouw">Mevrouw</option>
                <option value="De heer">De heer</option>
              </Form.Control>
              <Form.Control
                className="mt-2"
                type="text"
                placeholder="Voorletters"
                required
                value={voorletters}
                onChange={(e) => setVoorletters(e.target.value)}
              />
              <Form.Control
                className="mt-2"
                type="text"
                placeholder="Achternaam"
                required
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              <Form.Control
                className="mt-2"
                type="text"
                placeholder="Bedrijfsnaam"
                required
                value={bedrijfsnaam}
                onChange={(e) => setBedrijfsnaam(e.target.value)}
              />
              <Form.Control
                className="mt-2"
                type="text"
                placeholder="Adres"
                required
                value={adres}
                onChange={(e) => setAdres(e.target.value)}
              />
              <Form.Control
                className="mt-2"
                type="text"
                placeholder="Woonplaats"
                required
                value={woonplaats}
                onChange={(e) => setWoonplaats(e.target.value)}
              />
              <Form.Control
                className="mt-2"
                type="text"
                placeholder="Geboortedatum"
                required
                value={geboortedatum}
                onChange={(e) => setGeboortedatum(e.target.value)}
              />
              <Form.Control
                className="mt-2"
                type="text"
                placeholder="Bsn"
                required
                value={bsn}
                onChange={(e) => setBsn(e.target.value)}
              />
              <Form.Control
                className="mt-2"
                type="text"
                placeholder="Iban"
                required
                value={iban}
                onChange={(e) => setIban(e.target.value)}
              />
              <Form.Control
                className="mt-2"
                type="text"
                placeholder="Kvk nr."
                required
                value={kvk}
                onChange={(e) => setKvk(e.target.value)}
              />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
              <Button
                variant="secondary"
                onClick={closeModal}
                size="sm"
                className="me-2"
              >
                Sluiten
              </Button>
              <Button
                type="submit"
                variant="primary"
                size="sm"
                className="me-2"
                disabled={loading}
              >
                {" "}
                Opdrachtnemer toevoegen{" "}
              </Button>
            </div>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
}
