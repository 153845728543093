import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SignaturePad from "react-signature-canvas";
import {
  faExternalLinkAlt,
  faShoppingCart,
  faDownload,
} from "@fortawesome/free-solid-svg-icons";
import { PDFDocument, StandardFonts } from "pdf-lib";
import styles from "./styles.module.css";
import moment from "moment";

import {
  Col,
  Row,
  Card,
  Image,
  Button,
  Container,
  Navbar,
  Nav,
  Modal,
  Tooltip,
  OverlayTrigger,
} from "@themesberg/react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { Routes } from "../routes";
import ReactHero from "../assets/img/technologies/ontzorg_transparent.svg";
import ReactMockupImg from "../assets/img/react-mockup.png";
import { doc, getDoc, setDoc, serverTimestamp } from "firebase/firestore";
import {
  uploadString,
  ref,
  getDownloadURL,
  uploadBytes,
  uploadBytesResumable,
} from "firebase/storage";
import { db, storage2 } from "../firebase";

export default () => {
  const { id } = useParams();
  const [data, setData] = useState("");
  const [imgUrl, setImgUrl] = useState([]);

  const docRef = doc(db, `/contracts/${id}/opdrachtnemer`, "1");

  useEffect(() => {
    const getClientInfo = async () => {
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        setData(docSnap.data());
      } else {
        // doc.data() will be undefined in this case
        console.log("No such document!");
      }
    };

    getClientInfo();
  }, []);

  const [loading, setLoading] = useState(true);

  const [trimmedDataURL, setTrimmedDataURL] = useState("");
  let [sigPad, setSigpad] = useState({});
  let [pdfBytes] = useState();

  const [open, setOpen] = useState(false);

  function clear(e) {
    e.preventDefault();
    sigPad.clear();
    setTrimmedDataURL("");
  }
  const trim = (e) => {
    setLoading(true);
    e.preventDefault();
    setTrimmedDataURL(sigPad.getTrimmedCanvas().toDataURL("image/png"));
    setLoading(false);
  };

  //Handle the modification of existing pdf
  function handleSubmit(e) {
    e.preventDefault();
    setLoading(true);

    if (data.OG_sig_img != null) {
      async function modifyPdf() {
        // Fetch an existing PDF document
        fetch(data.pdf_file)
          .then((res) => res.arrayBuffer())
          .then(async (arrayBuffer) => {
            // Load a PDFDocument from the existing PDF bytes

            const pdfDoc = await PDFDocument.load(arrayBuffer);
            // Embed the Helvetica font
            const helveticaFont = await pdfDoc.embedFont(
              StandardFonts.Helvetica
            );

            // Get the first page of the document
            const pages = pdfDoc.getPages();
            const firstPage = pages[pages.length - 1];

            //  Signature image

            const jpgImageBytes = trimmedDataURL;
            const OgImgUrl = data.OG_sig_img;

            const pngImageBytes = await fetch(OgImgUrl).then((res) =>
              res.arrayBuffer()
            );

            const jpgImage = await pdfDoc.embedPng(jpgImageBytes);
            const jpgImageOg = await pdfDoc.embedPng(pngImageBytes);
            const jpgDims = jpgImage.scale(0.75);
            const jpgDims2 = jpgImageOg.scale(0.75);
            let currentTime = moment().format("MMMM Do YYYY, h:mm:ss a");

            let OGTime = data.OG_signedAt.toDate();
            let OgStringDate = moment(OGTime).format("MMMM Do YYYY, h:mm:ss a");

            if (data.overeenkomst === 2) {
              firstPage.drawImage(jpgImage, {
                x: firstPage.getWidth() / 2,
                y: firstPage.getHeight() / 2.9,
                width: jpgDims.width,
                height: jpgDims.height * 0.8,
              });

              firstPage.moveTo(
                firstPage.getWidth() / 2,
                firstPage.getHeight() / 3
              );
              firstPage.drawText(currentTime, {
                size: 14,
                font: helveticaFont,
              });

              firstPage.drawImage(jpgImageOg, {
                x: firstPage.getWidth() / 9,
                y: firstPage.getHeight() / 2.9,
                width: jpgDims.width,
                height: jpgDims.height * 0.8,
              });

              firstPage.moveTo(
                firstPage.getWidth() / 9,
                firstPage.getHeight() / 3
              );
              firstPage.drawText(OgStringDate, {
                size: 14,
                font: helveticaFont,
              });
            } else if (data.overeenkomst === 3) {
              firstPage.drawImage(jpgImage, {
                x: firstPage.getWidth() / 1.9,
                y: firstPage.getHeight() / 1.65,
                width: jpgDims.width,
                height: jpgDims.height * 0.8,
              });

              firstPage.moveTo(
                firstPage.getWidth() / 2,
                firstPage.getHeight() / 2.5
              );
              firstPage.drawImage(jpgImageOg, {
                x: firstPage.getWidth() / 9,
                y: firstPage.getHeight() / 1.65,
                width: jpgDims2.width,
                height: jpgDims2.height * 0.8,
              });

              firstPage.moveTo(
                firstPage.getWidth() / 9,
                firstPage.getHeight() / 1.7
              );
              firstPage.drawText(OgStringDate, {
                size: 14,
                font: helveticaFont,
              });

              firstPage.moveTo(
                firstPage.getWidth() / 1.9,
                firstPage.getHeight() / 1.7
              );
              firstPage.drawText(currentTime, {
                size: 14,
                font: helveticaFont,
              });
            } else {
              firstPage.drawImage(jpgImage, {
                x: firstPage.getWidth() / 2,
                y: firstPage.getHeight() / 14,
                width: jpgDims.width,
                height: jpgDims.height * 0.8,
              });

              firstPage.moveTo(
                firstPage.getWidth() / 2,
                firstPage.getHeight() / 18
              );
              firstPage.drawText(currentTime, {
                size: 14,
                font: helveticaFont,
              });

              firstPage.drawImage(jpgImageOg, {
                x: firstPage.getWidth() / 9,
                y: firstPage.getHeight() / 14,
                width: jpgDims.width,
                height: jpgDims.height * 0.8,
              });

              firstPage.moveTo(
                firstPage.getWidth() / 9,
                firstPage.getHeight() / 18
              );
              firstPage.drawText(OgStringDate, {
                size: 14,
                font: helveticaFont,
              });
            }

            // Serialize the PDFDocument to bytes (a Uint8Array)
            const finalPdf = await pdfDoc.save();

            const storageRef2 = ref(
              storage2,
              `/files/on_handtekeningen/${id}.pdf`
            );
            uploadBytesResumable(
              storageRef2,
              finalPdf,
              new Blob([finalPdf], { type: "application/pdf" })
            )
              .then((snapshot) => {
                getDownloadURL(snapshot.ref).then((downloadURL) => {
                  setDoc(
                    doc(db, `contracts/${id}/opdrachtnemer`, "1"),
                    {
                      onsigned: true,
                      signed_contract: downloadURL,
                    },
                    { merge: true }
                  );
                });
              })
              .then(setOpen(true));
          });
      }
      modifyPdf();
    } else {
      setOpen(true);
    }

    ///////////////////////////////////////////////////////////////////////////////////////////////

    //upload van handtekening

    const storageRef = ref(
      storage2,
      `/files/on_handtekeningen/onSig-${id}.png`
    );

    uploadString(storageRef, trimmedDataURL, "data_url").then((snapshot) => {
      getDownloadURL(snapshot.ref).then((downloadURL) => {
        setDoc(
          doc(db, `contracts/${id}/opdrachtnemer`, "1"),
          {
            status: "Compleet",
            parentId: id,
            ON_signedAt: serverTimestamp(),
            signcount: 1,
            ON_sig_img: downloadURL,
          },
          { merge: true }
        );
      });
    });
  }

  const PagePreview = (props) => {
    const { name, image, link } = props;

    return (
      <Col xs={6} className="mb-5">
        <Card.Link
          as={Link}
          to={link}
          className="page-preview page-preview-lg scale-up-hover-2"
        >
          <Image
            src={image}
            className="shadow-lg rounded scale"
            alt="Dashboard page preview"
          />

          <div className="text-center show-on-hover">
            <h6 className="m-0 text-center text-white">
              {name}{" "}
              <FontAwesomeIcon icon={faExternalLinkAlt} className="ms-2" />
            </h6>
          </div>
        </Card.Link>
      </Col>
    );
  };
  function closeModal() {
    setOpen(false);
  }

  return (
    <>
      <Modal show={open} onHide={closeModal} backdrop="static">
        <Modal.Header>
          <Modal.Title className="success">
            Geslaagd! U kunt dit venster nu sluiten
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Card className="p-5">
            <p>
              Uw contract is nu voorzien van uw handtekening.<br></br>Wij zenden
              u het definitieve contract per mail zodra beide partijen hebben
              getekend.
            </p>
          </Card>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
      <Navbar
        variant="dark"
        expand="lg"
        bg="dark"
        className="navbar-transparent navbar-theme-primary sticky-top"
      >
        <Container className="position-relative justify-content-between px-3">
          <Navbar.Brand
            as={HashLink}
            to="#home"
            className="me-lg-3 d-flex align-items-center"
          >
            <Image src={ReactHero} />
            <span className="ms-2 brand-text d-none d-md-inline">
              Ontzorgcontract.nl
            </span>
          </Navbar.Brand>

          <div className="d-flex align-items-center">
            <Navbar.Collapse id="navbar-default-primary">
              <Nav className="navbar-nav-hover align-items-lg-center">
                <Nav.Link as={HashLink} to="#">
                  Features
                </Nav.Link>
                <Nav.Link as={HashLink} to="#">
                  Wie zijn wij
                </Nav.Link>
                <Nav.Link
                  as={HashLink}
                  to={Routes.DashboardOverview.path}
                  className="d-sm-none d-xl-inline"
                >
                  Data veiligheid
                </Nav.Link>
              </Nav>
            </Navbar.Collapse>
            <Button
              as={Link}
              to={Routes.DashboardOverview.path}
              variant="outline-white"
              className="ms-3"
            >
              <FontAwesomeIcon icon={faDownload} className="me-1" /> Aan de slag
            </Button>
          </div>
        </Container>
      </Navbar>

      <section className="section section-md bg-soft pt-lg-3" id="features">
        <Container>
          <Row className="d-md-none">
            <Col>Scroll naar beneden om uw handtekening te plaatsen</Col>
          </Row>
          <Row className="justify-content-center mt-1 mt-lg-2 mb-xs-2">
            <Col xs={12} lg={8} className="p-2">
              <Card shadow="lg" style={{ height: "80vh" }}>
                <iframe height="100%" title="pdf" src={data.pdf_file} />
              </Card>
            </Col>
            <Col
              xs={12}
              lg={4}
              className="mt-lg-10 mt-xs-2 text-center pb-sm-5"
            >
              <h3>Ondertekenen</h3>
              <Card shadow="lg" className="p-5">
                <p>
                  Plaats bij akkoord uw handtekening hieronder, u ontvangt het
                  getekende contract in uw mailbox.
                </p>
                <div className={styles.container}>
                  <div className={styles.sigContainer}>
                    <SignaturePad
                      canvasProps={{ className: styles.sigPad }}
                      ref={(ref) => {
                        setSigpad(ref);
                      }}
                    />
                    <div> </div>
                  </div>
                  <div className="mt-2 text-center">
                    <Button
                      variant="primary"
                      className="btn-sm"
                      onClick={clear}
                    >
                      Reset
                    </Button>
                    <Button
                      variant="primary"
                      className="btn-sm mx-2"
                      onClick={trim}
                    >
                      Bevestig
                    </Button>
                    {/* <button className={styles.buttons} onClick={trim}>
                      Accepteer
                    </button> */}
                  </div>
                </div>
              </Card>

              <OverlayTrigger
                overlay={
                  <Tooltip id="tooltip-disabled">
                    Bevestig uw handtekening hierboven voor u verder gaat
                  </Tooltip>
                }
              >
                <span className="d-inline-block mt-3 mb-3">
                  <Button
                    disabled={loading}
                    onClick={handleSubmit}
                    target="_blank"
                  >
                    Handtekening plaatsen
                  </Button>
                </span>
              </OverlayTrigger>
            </Col>
          </Row>
          <Row className="justify-content-between align-items-center mb-5 mb-lg-7">
            <Col lg={5} className="order-lg-2 mb-5 mb-lg-0">
              <h2>Ontzorgcontract?</h2>
              <p className="mb-3 lead fw-bold">
                Een razendsnelle tool voor het opstellen van contracten voor
                aannemers en onderaannemers in de zorg!
              </p>
              <p className="mb-4"></p>
              <Button
                as={Link}
                to={Routes.DashboardOverview.path}
                variant="secondary"
                target="_blank"
              >
                Live Demo{" "}
                <FontAwesomeIcon icon={faExternalLinkAlt} className="ms-1" />
              </Button>
              <Button
                as={HashLink}
                to="#download"
                variant="outline-primary"
                className="ms-3"
              >
                <FontAwesomeIcon icon={faShoppingCart} className="me-1" />{" "}
                Registreren
              </Button>
            </Col>
            <Col lg={6} className="order-lg-1">
              <Image src={ReactMockupImg} alt="Calendar Preview" />
            </Col>
          </Row>
        </Container>
      </section>
      <footer className="footer py-6 bg-dark text-white">
        <Container>
          <Row>
            <Col md={4}>
              <Navbar.Brand
                as={HashLink}
                to="#home"
                className="me-lg-3 mb-3 d-flex align-items-center"
              >
                <Image src={ReactHero} />
                <span className="ms-2 brand-text">Ontzorgcontract</span>
              </Navbar.Brand>
              <p>
                Ontzorgcontract is een populaire en razendsnelle tool om
                contracten te regelen.
              </p>
            </Col>
            <Col xs={6} md={2} className="mb-5 mb-lg-0">
              <span className="h5">Over ons</span>
              <ul className="links-vertical mt-2">
                <li>
                  <Card.Link target="_blank" href="#">
                    Blog
                  </Card.Link>
                </li>
                <li>
                  <Card.Link target="_blank" href="#">
                    Producten
                  </Card.Link>
                </li>
                <li>
                  <Card.Link target="_blank" href="#">
                    Team
                  </Card.Link>
                </li>
                <li>
                  <Card.Link target="_blank" href="#">
                    Contact
                  </Card.Link>
                </li>
              </ul>
            </Col>
            <Col xs={6} md={2} className="mb-5 mb-lg-0">
              <span className="h5">Extra</span>
              <ul className="links-vertical mt-2">
                <li>
                  <Card.Link
                    as={Link}
                    to={Routes.DocsQuickStart.path}
                    target="_blank"
                  >
                    Waar begin ik?
                  </Card.Link>
                </li>
                <li>
                  <Card.Link
                    as={Link}
                    to={Routes.DocsChangelog.path}
                    target="_blank"
                  >
                    Updates
                  </Card.Link>
                </li>
                <li>
                  <Card.Link target="_blank" href="#">
                    Legal
                  </Card.Link>
                </li>
              </ul>
            </Col>
            <Col xs={12} md={4} className="mb-5 mb-lg-0">
              <span className="h5 mb-3 d-block">Nieuwsbrief</span>
              <form action="#">
                <div className="form-row mb-2">
                  <div className="col-12">
                    <input
                      type="email"
                      className="form-control mb-2"
                      placeholder="zorgverlener@ontzorgcontract.nl"
                      name="email"
                      aria-label="Subscribe form"
                      required
                    />
                  </div>
                  <div className="col-12">
                    <button
                      type="submit"
                      className="btn btn-secondary text-dark shadow-soft btn-block"
                      data-loading-text="Sending"
                    >
                      <span>Inschrijven</span>
                    </button>
                  </div>
                </div>
              </form>
              <p className="text-muted font-small m-0">
                Wij delen nooit uw informatie met derden, bekijk onze{" "}
                <Card.Link className="text-white" href="#">
                  Privacy Policy
                </Card.Link>
              </p>
            </Col>
          </Row>
          <hr className="bg-gray my-5" />
          <Row>
            <Col className="mb-md-2">
              <Card.Link
                href=""
                target="_blank"
                className="d-flex justify-content-center"
              >
                {/* <Image
                  src={ThemesbergLogo}
                  height={35}
                  className="d-block mx-auto mb-3"
                  alt="Themesberg Logo"
                /> */}
              </Card.Link>
              <div
                className="d-flex text-center justify-content-center align-items-center"
                role="contentinfo"
              >
                <p className="font-weight-normal font-small mb-0">
                  Copyright © Ontzorgcontract
                  <span className="current-year">2022</span>. All rights
                  reserved.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
    </>
  );
};
