import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAuth } from "../contexts/AuthContext";
import { faCog, faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import { Button } from "@themesberg/react-bootstrap";

import {
  Nav,
  Navbar,
  Dropdown,
  Container,
  Alert,
} from "@themesberg/react-bootstrap";

export default (props) => {
  const { currentUser, logout, resendEmailVerification } = useAuth();
  const [loading, setLoading] = useState(false);

  const sendAgain = () => {
    setLoading(true);
    resendEmailVerification();
  };

  function AlertDismissibleExample() {
    return (
      <Alert variant="danger">
        Uw e-mailadres is nog niet geverifieerd. Klik op de link in uw e-mail om
        contracten te kunnen verzenden. Email niet ontvangen? {"   "}
        <Button
          variant="primary"
          className="btn-sm p-1"
          target="blank"
          onClick={sendAgain}
          disabled={loading}
        >
          Opnieuw sturen
        </Button>
      </Alert>
    );
  }

  return (
    <>
      <Navbar variant="dark" expanded className="ps-0 pe-2 pb-0">
        <Container fluid className="px-0">
          <div className="d-flex justify-content-between w-100">
            <div className="d-flex align-items-center"></div>
            <Nav className="align-items-center">
              {/* <Dropdown as={Nav.Item} onToggle={markNotificationsAsRead}>
              <Dropdown.Toggle
                as={Nav.Link}
                className="text-dark icon-notifications me-lg-3"
              >
                <span className="icon icon-sm">
                  <FontAwesomeIcon icon={faBell} className="bell-shake" />
                  {areNotificationsRead ? null : (
                    <span className="icon-badge rounded-circle unread-notifications" />
                  )}
                </span>
              </Dropdown.Toggle>
              {/* <Dropdown.Menu className="dashboard-dropdown notifications-dropdown dropdown-menu-lg dropdown-menu-center mt-2 py-0">
                <ListGroup className="list-group-flush">
                  <Nav.Link
                    href="#"
                    className="text-center text-primary fw-bold border-bottom border-light py-3"
                  >
                    Notifications
                  </Nav.Link>

                  {notifications.map((n) => (
                    <Notification key={`notification-${n.id}`} {...n} />
                  ))}

                  <Dropdown.Item className="text-center text-primary fw-bold py-3">
                    View all
                  </Dropdown.Item>
                </ListGroup>
              </Dropdown.Menu>
            </Dropdown> */}
              <Dropdown as={Nav.Item}>
                <Dropdown.Toggle as={Nav.Link} className="pt-1 px-0">
                  <div className="media d-flex align-items-center">
                    <span className="icon icon-sm text-dark">
                      <FontAwesomeIcon icon={faCog} className="me-2" />
                    </span>

                    <div className="media-body ms-2 text-dark align-items-center d-none d-lg-block">
                      <span className="mb-0 font-small fw-bold">
                        {currentUser && currentUser.email}
                      </span>
                    </div>
                  </div>
                </Dropdown.Toggle>
                <Dropdown.Menu className="user-dropdown dropdown-menu-right mt-2">
                  {/* <Dropdown.Item className="fw-bold">
                  <FontAwesomeIcon icon={faUserCircle} className="me-2" /> My
                  Profile
                </Dropdown.Item>
                <Dropdown.Item className="fw-bold">
                  <FontAwesomeIcon icon={faCog} className="me-2" /> Settings
                </Dropdown.Item>
                <Dropdown.Item className="fw-bold">
                  <FontAwesomeIcon icon={faEnvelopeOpen} className="me-2" />{" "}
                  Messages
                </Dropdown.Item>
                <Dropdown.Item className="fw-bold">
                  <FontAwesomeIcon icon={faUserShield} className="me-2" />{" "}
                  Support
                </Dropdown.Item>

                <Dropdown.Divider /> */}

                  <Dropdown.Item className="fw-bold" onClick={logout}>
                    <FontAwesomeIcon
                      icon={faSignOutAlt}
                      className="text-danger me-2"
                    />{" "}
                    Logout
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Nav>
          </div>
        </Container>
      </Navbar>
      {currentUser.emailVerified ? <></> : <AlertDismissibleExample />}
    </>
  );
};
