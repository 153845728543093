import React, { useState } from "react";

import {
  Col,
  Row,
  Button,
  Form,
  Card,
  Container,
} from "@themesberg/react-bootstrap";

import { useHistory } from "react-router-dom";

import { db, storage2 } from "../firebase";
import {
  ref,
  uploadString,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";
import { collection, addDoc, serverTimestamp } from "firebase/firestore";
import { useAuth } from "../contexts/AuthContext";
import SignaturePad from "react-signature-canvas";
import styles from "./styles.module.css";
import { v4 as uuidV4 } from "uuid";

export default () => {
  const [name, setName] = useState("");
  const [aanhef, setAanhef] = useState("");
  const [voorletters, setVoorletters] = useState("");
  const [bedrijfsnaam, setBedrijfsnaam] = useState("");
  const [adres, setAdres] = useState("");
  const [woonplaats, setWoonplaats] = useState("");
  const [kvk, setKvk] = useState("");
  const { currentUser } = useAuth();
  const [loading, setLoading] = useState(true);
  const [validated, setValidated] = useState(false);

  const [trimmedDataURL, setTrimmedDataURL] = useState("");
  let [sigPad, setSigpad] = useState({});

  const id = uuidV4();
  let history = useHistory();

  function clear(e) {
    e.preventDefault();
    sigPad.clear();
    setTrimmedDataURL("");
  }
  const trim = (e) => {
    setLoading(true);
    e.preventDefault();

    setTrimmedDataURL(sigPad.getTrimmedCanvas().toDataURL("image/png"));
    setLoading(false);
  };

  function handleSubmit(e) {
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    } else {
      e.preventDefault();
      const metadata = {
        contentType: "image/png",
      };

      const storageRef = ref(storage2, `/files/${currentUser.uid}/${id}.png`);

      const uploadTask = uploadBytesResumable(
        storageRef,
        trimmedDataURL,
        metadata
      );

      // Register three observers:
      // 1. 'state_changed' observer, called any time the state changes
      // 2. Error observer, called on failure
      // 3. Completion observer, called on successful completion
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          // Observe state change events such as progress, pause, and resume
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log("Upload is " + progress + "% done");
          switch (snapshot.state) {
            case "paused":
              console.log("Upload is paused");
              break;
            case "running":
              console.log("Upload is running");
              break;
            default:
              break;
          }
        },
        (error) => {
          // Handle unsuccessful uploads
        },
        () => {
          // Handle successful uploads on complete
          // For instance, get the download URL: https://firebasestorage.googleapis.com/...
          uploadString(storageRef, trimmedDataURL, "data_url")
            .then((snapshot) => {
              console.log("Uploaded a data_url string!");
            })
            .then(
              getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                addDoc(collection(db, "opdrachtgevers"), {
                  aanhef: aanhef,
                  voorletters: voorletters,
                  name: name,
                  bedrijfsnaam: bedrijfsnaam,
                  adres: adres,
                  woonplaats: woonplaats,
                  kvk: kvk,
                  userId: currentUser.uid,
                  sigImg: `https://storage.googleapis.com/ontzorgcontractsig/files/${currentUser.uid}/${id}.png`,
                  createdAt: serverTimestamp(),
                });
                setAanhef("");
                setVoorletters("");
                setBedrijfsnaam("");
                setAdres("");
                setWoonplaats("");
                setKvk("");
                setName("");
                setLoading(false);
                history.push("/handtekeningen");
              })
            );
        }
      );
    }
    setValidated(true);
  }
  return (
    <>
      <Container fluid className="">
        {" "}
        <Container className="align-items-center ">
          <Row>
            <Col>
              <Card>
                <Card.Body>
                  {" "}
                  <Form
                    noValidate
                    validated={validated}
                    onSubmit={handleSubmit}
                  >
                    <Form.Group className="mb-3">
                      <Form.Label>Aanhef</Form.Label>
                      <Form.Control
                        required
                        className="mt-2"
                        as="select"
                        value={aanhef}
                        placeholder="Aanhef"
                        onChange={(e) => {
                          setAanhef(e.target.value);
                        }}
                      >
                        <option value=""></option>
                        <option value="Mevrouw">Mevrouw</option>
                        <option value="De heer">De heer</option>
                      </Form.Control>
                      <Form.Control.Feedback>Prima!</Form.Control.Feedback>
                      <Form.Control.Feedback type="invalid">
                        Kies een aanhef
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>Voorletters</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Voorletters"
                        required
                        value={voorletters}
                        onChange={(e) => setVoorletters(e.target.value)}
                      />
                      <Form.Control.Feedback type="invalid">
                        Vul correcte voorletters in
                      </Form.Control.Feedback>
                    </Form.Group>{" "}
                    <Form.Group className="mb-3">
                      <Form.Label>Achternaam</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Achternaam"
                        required
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      />
                      <Form.Control.Feedback type="invalid">
                        Vul een correcte achternaam in
                      </Form.Control.Feedback>
                    </Form.Group>{" "}
                    <Form.Group className="mb-3">
                      <Form.Label>Bedrijfsnaam</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Bedrijfsnaam"
                        required
                        value={bedrijfsnaam}
                        onChange={(e) => setBedrijfsnaam(e.target.value)}
                      />
                      <Form.Control.Feedback type="invalid">
                        Vul een correcte bedrijfsnaam in
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>Adres</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Adres"
                        required
                        value={adres}
                        onChange={(e) => setAdres(e.target.value)}
                      />
                      <Form.Control.Feedback type="invalid">
                        Vul een correct adres in
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>Woonplaats</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Woonplaats"
                        required
                        value={woonplaats}
                        onChange={(e) => setWoonplaats(e.target.value)}
                      />
                      <Form.Control.Feedback type="invalid">
                        Vul een correcte woonplaats in
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>KVK nr.</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Kvk nr."
                        required
                        value={kvk}
                        onChange={(e) => setKvk(e.target.value)}
                      />
                      <Form.Control.Feedback type="invalid">
                        Vul correct kvk nummer in
                      </Form.Control.Feedback>
                      <Container>
                        <Container fluid>
                          <Row>
                            <Col xs={12} s={12} md={12} lg={6}>
                              <p className="pt-4 text-center">
                                Zet hieronder uw handtekening:
                              </p>
                              <div className={styles.container}>
                                <div className={styles.sigContainer}>
                                  <SignaturePad
                                    canvasProps={{ className: styles.sigPad }}
                                    ref={(ref) => {
                                      setSigpad(ref);
                                    }}
                                  />
                                  <div></div>
                                </div>
                                <div className="text-center">
                                  <Button
                                    variant="primary"
                                    onClick={clear}
                                    className="btn-sm"
                                  >
                                    Verwijder
                                  </Button>
                                  <Button
                                    variant="primary"
                                    onClick={trim}
                                    className="btn-sm m-2"
                                  >
                                    Accepteer
                                  </Button>
                                </div>
                              </div>
                            </Col>
                            <Col>
                              {trimmedDataURL ? (
                                <img
                                  alt="Handtekening"
                                  className={styles.sigImage}
                                  src={trimmedDataURL}
                                />
                              ) : null}
                            </Col>
                          </Row>
                        </Container>
                      </Container>
                    </Form.Group>
                    <Row>
                      {" "}
                      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                        <Button
                          variant="secondary"
                          href="/#handtekeningen"
                          size="sm"
                          className="me-2"
                        >
                          Terug
                        </Button>
                        <Button
                          type="submit"
                          variant="primary"
                          size="sm"
                          className="me-2"
                          disabled={loading}
                        >
                          {" "}
                          Handtekening toevoegen{" "}
                        </Button>
                      </div>
                    </Row>
                  </Form>
                </Card.Body>{" "}
              </Card>
            </Col>
          </Row>
        </Container>
      </Container>
    </>
  );
};
