import React, { useState, useEffect } from "react";
import { Col, Row, Card, Button, Alert } from "@themesberg/react-bootstrap";
import { useHistory } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import { useOpdrachtgever } from "./../utils/useOpdrachtgever";
import { useFolder } from "./../utils/useFolder";
import NewContractForm from "./components/NewContractForm";
import { doc, setDoc, serverTimestamp } from "firebase/firestore";
import { v4 as uuidV4 } from "uuid";
import { db } from "../firebase";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell } from "@fortawesome/free-solid-svg-icons";

export default () => {
  const { childFolders } = useFolder();
  const { childOpdrachtgevers } = useOpdrachtgever();
  const [opdrachtgever, setOpdrachtgever] = useState("");
  const [folder, setFolder] = useState("");
  const [folderinfo, setFolderinfo] = useState();
  const [opdrachtgeverinfo, setOpdrachtgeverinfo] = useState();
  const [overeenkomst, setOvereenkomst] = useState("");
  const [loading, setLoading] = useState(true);
  const { currentUser } = useAuth();
  var history = useHistory();

  useEffect(() => {
    setFolderinfo(
      childFolders.filter((childFolder) => childFolder.id === folder)
    );

    setOpdrachtgeverinfo(
      childOpdrachtgevers.filter(
        (childOpdrachtgever) => childOpdrachtgever.id === opdrachtgever
      )
    );
  }, [folder, opdrachtgever, childFolders, childOpdrachtgevers]);

  async function handleSubmit(e) {
    e.preventDefault();

    setLoading(true);

    const cid = uuidV4();
    const ogid = uuidV4();
    const conditionalSigimg = opdrachtgeverinfo[0].sigImg
      ? opdrachtgeverinfo[0].sigImg
      : null;

    setDoc(doc(db, "contracts", cid), {
      opdrachtgeverId: opdrachtgever,
      opdrachtnemerId: folder,
      overeenkomst: overeenkomst,
      opdrachtnemer_aanhef: folderinfo[0].aanhef,
      opdrachtnemer_voorletters: folderinfo[0].voorletters,
      opdrachtnemer_achternaam: folderinfo[0].name,
      opdrachtnemer_bedrijfsnaam: folderinfo[0].bedrijfsnaam,
      opdrachtnemer_adres: folderinfo[0].adres,
      opdrachtnemer_woonplaats: folderinfo[0].woonplaats,
      opdrachtnemer_geboortedatum: folderinfo[0].geboortedatum,
      opdrachtnemer_bsn: folderinfo[0].bsn,
      opdrachtnemer_iban: folderinfo[0].iban,
      opdrachtnemer_kvk: folderinfo[0].kvk,
      ogid: ogid,

      opdrachtgever_aanhef: opdrachtgeverinfo[0].aanhef,
      opdrachtgever_voorletters: opdrachtgeverinfo[0].voorletters,
      opdrachtgever_achternaam: opdrachtgeverinfo[0].name,
      opdrachtgever_bedrijfsnaam: opdrachtgeverinfo[0].bedrijfsnaam,
      opdrachtgever_adres: opdrachtgeverinfo[0].adres,
      opdrachtgever_woonplaats: opdrachtgeverinfo[0].woonplaats,
      opdrachtgever_kvk: opdrachtgeverinfo[0].kvk,
      opdrachtgever_signature: conditionalSigimg,

      userId: currentUser.uid,
      createdAt: serverTimestamp(),
    }).then(() => {
      history.push(`/contract-aanmaken/${cid}`);
    });
  }

  return (
    <>
      <Row>
        {childFolders.length === 0 ? (
          <Col xs={12} md={6} lg={6} xl={3} className="mb-4 text-center">
            <Alert variant="info">
              <Alert.Heading className="d-flex align-items-center mt-2">
                <FontAwesomeIcon icon={faBell} className="icon icon-md me-2" />
                Nog geen opdrachtnemers toegevoegd
              </Alert.Heading>
              <p>Voeg allereerst 1 of meerdere opdrachtnemers toe</p>
              <hr />
              <Button
                variant="primary"
                size="sm"
                className="me-2"
                href="/#opdrachtnemers/nieuw"
              >
                Opdrachtnemer toevoegen
              </Button>
            </Alert>
          </Col>
        ) : (
          <Col xs={12} xl={3}>
            <NewContractForm
              folders={childFolders}
              opdrachtgevers={childOpdrachtgevers}
              setOpdrachtgever={setOpdrachtgever}
              setFolder={setFolder}
              setOvereenkomst={setOvereenkomst}
              handleSubmit={handleSubmit}
              loading={loading}
              setLoading={setLoading}
            />
          </Col>
        )}
        <Col xs={12} xl={4}>
          <Row>
            <Col xs={12}>
              <Card border="light" className="text-center p-0 mb-4 shadow">
                <div />
                <Card.Body className="pb-5">
                  <Card.Title>{overeenkomst}</Card.Title>
                  {childFolders
                    .filter((childFolder) => childFolder.id === folder)
                    .map((filteredFolder) => (
                      <>
                        <Card.Title>
                          {filteredFolder.voorletters} {filteredFolder.name}
                        </Card.Title>
                        <Card.Subtitle className="fw-normal">
                          {filteredFolder.bedrijfsnaam}
                        </Card.Subtitle>
                        <Card.Text className="text-gray mb-4">
                          {filteredFolder.adres}, {filteredFolder.woonplaats}
                        </Card.Text>
                        {childOpdrachtgevers
                          .filter(
                            (childOpdrachtgever) =>
                              childOpdrachtgever.id === opdrachtgever
                          )
                          .map((filteredOpdrachtgever) => (
                            <Card.Body className="pb-5">
                              <Card.Title>
                                {filteredOpdrachtgever.voorletters}{" "}
                                {filteredOpdrachtgever.name}
                              </Card.Title>
                              <Card.Subtitle className="fw-normal">
                                {filteredOpdrachtgever.bedrijfsnaam}
                              </Card.Subtitle>
                              <Card.Text className="text-gray mb-4">
                                {filteredOpdrachtgever.adres},{" "}
                                {filteredOpdrachtgever.woonplaats}
                              </Card.Text>
                              {filteredOpdrachtgever.sigImg && (
                                <Card.Img
                                  src={filteredOpdrachtgever.sigImg}
                                  alt="handtekening"
                                  style={{ height: "80px", width: "130px" }}
                                />
                              )}
                            </Card.Body>
                          ))}
                      </>
                    ))}
                </Card.Body>
              </Card>
            </Col>
            <Col xs={12}></Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};
