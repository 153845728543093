import React from "react";

import { Col, Row, Card, Form, Button } from "@themesberg/react-bootstrap";

function NewContractForm({
  opdrachtgevers,
  folders,
  setOpdrachtgever,
  setFolder,
  setOvereenkomst,
  handleSubmit,
  loading,
  setLoading,
}) {
  return (
    <Card border="light" className="bg-white shadow mb-4">
      <Card.Body>
        <h5 className="mb-4">Contract aanmaken</h5>
        <Form>
          <Row>
            <Row className="align-items-center">
              <Col sm={12} className="mb-3">
                <Form.Group className="mb-2">
                  <Form.Label>Type contract</Form.Label>
                  <Form.Select
                    defaultValue="0"
                    onChange={(e) => setOvereenkomst(e.target.value)}
                  >
                    <option key="blancoOV"></option>
                    <option key="OV1" value="Raamovereenkomst">
                      Raamovereenkomst - ZZP
                    </option>
                    <option
                      key="OV2"
                      value="Overeenkomst van opdracht > zorg in natura"
                    >
                      Opdracht - Zorg in Natura - Client
                    </option>
                    <option
                      key="OV3"
                      value="Overeenkomst van opdracht > zorg in natura > Team"
                    >
                      Opdracht - Zorg in Natura - Team
                    </option>
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={12} className="mb-3">
                <Form.Group className="mb-2">
                  <Form.Label>Opdrachtnemer</Form.Label>
                  <Form.Select
                    id="state"
                    defaultValue="0"
                    onChange={(e) => setFolder(e.target.value)}
                  >
                    <option key="blanco12"></option>
                    {folders.map((folder) => (
                      <option key={folder.id} value={folder.id}>
                        {folder.name}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              {" "}
              <Col sm={12} className="mb-3">
                <Form.Group className="mb-2">
                  <Form.Label>Opdrachtgever</Form.Label>
                  <Form.Select
                    id="state"
                    defaultValue="0"
                    onChange={(e) => {
                      setOpdrachtgever(e.target.value);
                      setLoading(false);
                    }}
                  >
                    <option key="blancoF"></option>
                    {opdrachtgevers.map((opdrachtgever) => (
                      <option key={opdrachtgever.id} value={opdrachtgever.id}>
                        {opdrachtgever.voorletters} {opdrachtgever.name}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>
          </Row>

          <div className="mt-3">
            <Button variant="primary" disabled={loading} onClick={handleSubmit}>
              Volgende
            </Button>
          </div>
        </Form>
      </Card.Body>
    </Card>
  );
}

export default NewContractForm;
