import React, { useState } from "react";

import {
  Col,
  Row,
  Button,
  Form,
  Card,
  Container,
} from "@themesberg/react-bootstrap";

import { useHistory } from "react-router-dom";

import { db } from "../firebase";

import { collection, addDoc, serverTimestamp } from "firebase/firestore";
import { useAuth } from "../contexts/AuthContext";

import { v4 as uuidV4 } from "uuid";

export default () => {
  const [name, setName] = useState("");
  const [aanhef, setAanhef] = useState("");
  const [voorletters, setVoorletters] = useState("");
  const [bedrijfsnaam, setBedrijfsnaam] = useState("");
  const [adres, setAdres] = useState("");
  const [woonplaats, setWoonplaats] = useState("");
  const [kvk, setKvk] = useState("");
  const { currentUser } = useAuth();
  const [loading, setLoading] = useState(true);
  const [validated, setValidated] = useState(false);

  const [trimmedDataURL, setTrimmedDataURL] = useState("");
  let [sigPad, setSigpad] = useState({});

  const id = uuidV4();
  let history = useHistory();

  function handleSubmit(e) {
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    } else {
      e.preventDefault();
      addDoc(collection(db, "opdrachtgevers"), {
        aanhef: aanhef,
        voorletters: voorletters,
        name: name,
        bedrijfsnaam: bedrijfsnaam,
        adres: adres,
        woonplaats: woonplaats,
        kvk: kvk,
        userId: currentUser.uid,
        // sigImg: `https://storage.googleapis.com/ontzorgcontractsig/files/${currentUser.uid}/${id}.png`,
        createdAt: serverTimestamp(),
      });
      setAanhef("");
      setVoorletters("");
      setBedrijfsnaam("");
      setAdres("");
      setWoonplaats("");
      setKvk("");
      setName("");
      setLoading(false);
      history.push("/opdrachtgevers");
    }
    setValidated(true);
  }
  return (
    <>
      <Container fluid className="">
        {" "}
        <Container className="align-items-center ">
          <Row>
            <Col>
              <Card>
                <Card.Body>
                  {" "}
                  <Form
                    noValidate
                    validated={validated}
                    onSubmit={handleSubmit}
                  >
                    <Form.Group className="mb-3">
                      <Form.Label>Aanhef</Form.Label>
                      <Form.Control
                        required
                        className="mt-2"
                        as="select"
                        value={aanhef}
                        placeholder="Aanhef"
                        onChange={(e) => {
                          setAanhef(e.target.value);
                        }}
                      >
                        <option value=""></option>
                        <option value="Mevrouw">Mevrouw</option>
                        <option value="De heer">De heer</option>
                      </Form.Control>
                      <Form.Control.Feedback>Prima!</Form.Control.Feedback>
                      <Form.Control.Feedback type="invalid">
                        Kies een aanhef
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>Voorletters</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Voorletters"
                        required
                        value={voorletters}
                        onChange={(e) => setVoorletters(e.target.value)}
                      />
                      <Form.Control.Feedback type="invalid">
                        Vul correcte voorletters in
                      </Form.Control.Feedback>
                    </Form.Group>{" "}
                    <Form.Group className="mb-3">
                      <Form.Label>Achternaam</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Achternaam"
                        required
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      />
                      <Form.Control.Feedback type="invalid">
                        Vul een correcte achternaam in
                      </Form.Control.Feedback>
                    </Form.Group>{" "}
                    <Form.Group className="mb-3">
                      <Form.Label>Bedrijfsnaam</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Bedrijfsnaam"
                        required
                        value={bedrijfsnaam}
                        onChange={(e) => setBedrijfsnaam(e.target.value)}
                      />
                      <Form.Control.Feedback type="invalid">
                        Vul een correcte bedrijfsnaam in
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>Adres</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Adres"
                        required
                        value={adres}
                        onChange={(e) => setAdres(e.target.value)}
                      />
                      <Form.Control.Feedback type="invalid">
                        Vul een correct adres in
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>Woonplaats</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Woonplaats"
                        required
                        value={woonplaats}
                        onChange={(e) => setWoonplaats(e.target.value)}
                      />
                      <Form.Control.Feedback type="invalid">
                        Vul een correcte woonplaats in
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>KVK nr.</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Kvk nr."
                        required
                        value={kvk}
                        onChange={(e) => setKvk(e.target.value)}
                      />
                      <Form.Control.Feedback type="invalid">
                        Vul correct kvk nummer in
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Row>
                      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                        <Button
                          variant="secondary"
                          href="/#opdrachtgevers"
                          size="sm"
                          className="me-2"
                        >
                          Terug
                        </Button>
                        <Button
                          type="submit"
                          variant="primary"
                          size="sm"
                          className="me-2"
                        >
                          Opdrachtgever toevoegen
                        </Button>
                      </div>
                    </Row>
                  </Form>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </Container>
    </>
  );
};
