import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faCloudUploadAlt } from "@fortawesome/free-solid-svg-icons";
import { Button, Dropdown } from "@themesberg/react-bootstrap";
import { useParams } from "react-router-dom";

import OpdrachtnemersTable from "./OpdrachtNemerTable";
import AddFolderButton from "../components/AddFolderButton";
import AddFolderModal from "../components/AddFolderModal";

export default () => {
  const { id } = useParams();

  return (
    <>
      {" "}
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <Dropdown className="btn-toolbar">
          <Dropdown.Toggle
            as={Button}
            variant="primary"
            size="sm"
            className="me-2"
          >
            <FontAwesomeIcon icon={faPlus} className="me-2" />
            Nieuw
          </Dropdown.Toggle>
          <Dropdown.Menu className="dashboard-dropdown dropdown-menu-left mt-2">
            <AddFolderButton setOpen={id === "nieuw" ? true : false} />

            <Dropdown.Item className="fw-bold" href="/#opdrachtgever-toevoegen">
              <FontAwesomeIcon icon={faCloudUploadAlt} className="me-2" />{" "}
              Nieuwe opdrachtgever
            </Dropdown.Item>

            <Dropdown.Divider />
          </Dropdown.Menu>
        </Dropdown>
      </div>
      {id === "nieuw" ? <AddFolderModal /> : <></>}
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <h4>Opdrachtnemers</h4>
          <p className="mb-0">Overzicht van opdrachtnemers.</p>
        </div>
        <div className="btn-toolbar mb-2 mb-md-0"></div>
      </div>
      <div className="table-settings mb-4"></div>
      <OpdrachtnemersTable />
    </>
  );
};
