import { useReducer, useEffect } from "react";
import { useAuth } from "../contexts/AuthContext";
import { db } from "../firebase";
import { collection, query, where, onSnapshot } from "firebase/firestore";

const ACTIONS = {
  SET_CHILD_FOLDERS: "set-child-folders",
};

export const ROOT_FOLDER = { name: "Root", id: null, path: [] };

function reducer(state, { type, payload }) {
  switch (type) {
    case ACTIONS.SET_CHILD_FOLDERS:
      return {
        ...state,
        childFolders: payload.childFolders,
      };

    default:
      return state;
  }
}

export function useFolder(folderId = null, folder = null) {
  const { currentUser } = useAuth();
  const q = query(
    collection(db, "folders"),
    where("userId", "==", currentUser.uid)
  );
  const [state, dispatch] = useReducer(reducer, {
    childFolders: [],
  });

  useEffect(() => {
    const formatDoc = (doc) => {
      return { id: doc.id, ...doc.data() };
    };

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      dispatch({
        type: ACTIONS.SET_CHILD_FOLDERS,
        payload: { childFolders: querySnapshot.docs.map(formatDoc) },
      });
    });
  }, [folderId, currentUser]);

  return state;
}
